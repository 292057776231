import { p, status } from '@/plugins/permission'
import { dateFormat } from '@/plugins/helper'

export default {
  index: {
    filters: [{
      label: 'normal.state',
      option: status.courseChange,
      value: 'state'
    }, {
      label: 'normal.way',
      value: 'way',
      option: status.courseWay
    }, {
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime',
      default: ''
    }, {
      label: 'normal.beginAt',
      value: 'begin_at',
      type: 'datetime',
      default: ''
    }, {
      label: 'normal.endAt',
      value: 'end_at',
      type: 'datetime',
      default: ''
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.student',
      value: 'reason'
    }, {
      label: 'normal.state',
      option: status.courseChange,
      value: 'state'
    }, {
      label: 'normal.way',
      option: status.courseWay,
      value: 'way'
    }, {
      label: 'normal.time',
      value: 'created_at',
      component: (res) => {
        if (!res.created_at) {
          return ''
        }
        return dateFormat('YYYY-mm-dd HH:MM:SS', new Date(res.created_at.$date))
      }
    }, {
      label: 'normal.beginAt',
      value: 'begin_at',
      width: 323,
      component: (res) => {
        let data = res.begin_at
        if (res.end_at) {
          data += '到' + res.end_at
        }
        return data
      }
    }]
  },
  edit: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.teacher',
      value: 'teacher'
    }, {
      label: 'normal.tel',
      value: 'tel',
      rule: 'tel'
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.temperature',
      value: 'temperature'
    }, {
      label: 'normal.student',
      value: 'studentName'
    }, {
      label: 'normal.content',
      value: 'content'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.visitor,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.state' }, trigger: 'blur' }
      ]
    }]
  }
}
