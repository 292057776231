import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.support, p.schoolManage],
    addPath: {
      display: [p.support]
    },
    addButton: [{
      path: '/store_record/add',
      text: 'store.storeIn',
      query: { state: 1, content: '' },
      type: 'route',
      display: [p.support]
    }, {
      path: '/store_record/add',
      text: 'store.storeOut',
      query: { state: 2, content: '' },
      type: 'route',
      display: [p.support]
    }, {
      path: '/store_record/add',
      text: 'store.distroy',
      query: { state: 3, content: '' },
      type: 'route',
      display: [p.support]
    }, {
      path: '/store_record/add',
      text: 'store.old',
      query: { state: 4, content: '' },
      type: 'route',
      display: [p.support]
    }, {
      path: '/api/store_item/download',
      text: 'button.download',
      type: 'download',
      display: [p.schoolManage, p.support]
    }],
    filters: [{
      label: 'store.storePosition',
      value: 'store_position'
    }, {
      label: 'store.itemName',
      value: 'item_name'
    }, {
      label: 'store.cateName',
      value: 'category_id',
      type: 'cascader',
      cascaderOption: 'store_category'
    }],
    tableItems: [{
      label: 'store.name',
      value: 'store_id',
      option: 'store'
    }, {
      label: 'store.cateName',
      value: 'cate_name'
    }, {
      label: 'store.itemName',
      value: 'item_name',
      rewrite: true,
      pk: 'item_id'
    }, {
      label: 'normal.number',
      value: 'num'
    }, {
      label: 'normal.unit',
      value: 'units',
      rewrite: true,
      pk: 'item_id'
    }, {
      label: 'store.storePosition',
      value: 'store_position',
      rewrite: true,
      pk: 'item_id'
    }],
    buttonItems: [{
      buttonName: 'store.button.add',
      type: 'route',
      path: '/store_record/add?item_id[]=%store_id%&item_id[]=%category_id%&item_id[]=%item_id%',
      showIf: function (key) {
        return true
      },
      display: [p.support]
    }, {
      buttonName: 'button.detail',
      type: 'route',
      path: '/store_record/index?item_id=%item_id%',
      showIf: function (key) {
        return true
      }
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/store_item/edit?id=%item_id%',
      showIf: function (key) {
        return true
      }
    }]
  },
  add: {
    permission: [p.support],
    formItems: [{
      label: 'store.cateName',
      value: 'category_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.cateName' }, trigger: 'blur' }
      ],
      type: 'cascader',
      cascaderOption: 'store_category'
    }, {
      label: 'store.itemName',
      value: 'item_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.itemName' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.unit',
      value: 'units',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.unit' }, trigger: 'blur' }
      ]
    }, {
      label: 'store.storePosition',
      value: 'store_position'
    }]
  },
  edit: {
    permission: [p.support],
    formItems: [{
      label: 'store.cateName',
      value: 'category_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.cateName' }, trigger: 'blur' }
      ],
      type: 'cascader',
      cascaderOption: 'store_category'
    }, {
      label: 'store.itemName',
      value: 'item_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.itemName' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.unit',
      value: 'units',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.unit' }, trigger: 'blur' }
      ]
    }, {
      label: 'store.storePosition',
      value: 'store_position'
    }]
  }
}
