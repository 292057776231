import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const user = []

export default {
  index: {
    permission: [p.schoolManage, p.salerManage],
    addPath: {},
    addButton: [{
      text: 'button.addMany',
      type: 'route',
      path: '/customer/addList'
    }],
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.search',
      value: 'student_name'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.customer
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'customer.docs',
      value: 'docs',
      width: '500px',
      component: row => {
        let show = ''
        for (let i = 0; i < row.docs.length; i++) {
          show += row.docs[i].name + row.docs[i].created_at + row.docs[i].doc + '<br>'
        }
        return show
      }
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.customer,
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.father_name',
      value: 'father_name',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.father_tel',
      value: 'father_tel',
      width: '116px',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.mother_name',
      value: 'mother_name',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.mother_tel',
      value: 'mother_tel',
      width: '116px',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.come_from',
      value: 'come_from',
      width: '158px',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.student_name',
      value: 'student_name',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.school_name',
      value: 'school_name',
      width: '158px',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.class_name',
      value: 'class_name',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.remarks',
      value: 'remarks',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.created_at',
      value: 'created_at',
      width: '158px',
      rewrite: true,
      pk: 'cus_id'
    }, {
      label: 'customer.sexual',
      value: 'sexual',
      rewrite: true,
      pk: 'cus_id'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'contentAjax',
      path: '/api/customer/setUser?id=%cus_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/customer/delete?id=%cus_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '删除',
            value: 0
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'customer.student_name',
      value: 'student_name',
      default: ''
    }, {
      label: 'customer.come_from',
      value: 'come_from',
      default: ''
    }, {
      label: 'customer.sexual',
      value: 'sexual',
      default: ''
    }, {
      label: 'customer.student_idcard',
      value: 'student_idcard',
      default: ''
    }, {
      label: 'customer.school_name',
      value: 'school_name',
      default: ''
    }, {
      label: 'customer.class_name',
      value: 'class_name',
      default: ''
    }, {
      label: 'customer.father_name',
      value: 'father_name',
      default: ''
    }, {
      label: 'customer.father_tel',
      value: 'father_tel',
      default: ''
    }, {
      label: 'customer.mother_name',
      value: 'mother_name',
      default: ''
    }, {
      label: 'customer.mother_tel',
      value: 'mother_tel',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'customer.mother_tel' }, trigger: 'blur' }
      ]
    }, {
      label: 'customer.remarks',
      value: 'remarks',
      default: ''
    }]
  },
  addList: {
    permission: [p.schoolManage]
  }
}
