import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    filters: [{
      value: 'exam_id',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'normal.student',
      value: 'name'
    }, {
      label: 'examination.diff',
      value: 'diff'
    }, {
      label: 'normal.score',
      value: 'total_score'
    }, {
      label: 'examination.realScore',
      value: 'score'
    }],
    buttonItems: [{
      buttonName: 'examination.inputScore',
      type: 'route',
      path: '/examination_item/inputScore?exam_item_id=%exam_item_id%'
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/examination_question/index?exam_item_id=%exam_item_id%'
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/examination_item/delete?id=%exam_item_id%'
    }]
  },
  inputScore: {
    permission: [p.teacher]
  }
}
