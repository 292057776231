import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      label: 'knowledge.index',
      value: 'kn_content'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson|?teacher=1'
    }],
    tableItems: [{
      label: 'knowledge.index',
      value: 'kn_content',
      rewrite: true,
      pk: 'knowledge_id'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rewrite: true,
      pk: 'knowledge_id',
      option: 'lesson|?teacher=1'
    }]
  },
  add: {
    permission: [p.teacher],
    formItems: [{
      label: 'knowledge.index',
      value: 'kn_content',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'knowledge.index' }, trigger: 'blur' }
      ],
      display: [p.teacher]
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson|?teacher=1',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ]
    }]
  }
}
