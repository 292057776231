import error from './en_error'

const enTr = {
  formRule: {
    tel: {
      required: '请输入tel',
      type: '请输入正确的tel'
    }
  },
  button: {
    confirm: 'confirm',
    login: 'login'
  },
  normal: {
    hello: 'hello '
  },
  error
}

export { enTr }
