export default {
  user: [{
    value: 0,
    label: 'ban'
  }, {
    value: 1,
    label: '正常'
  }],
  identity: [{
    value: 1,
    label: '管理员'
  }, {
    value: 2,
    label: '老师'
  }],
  gender: [{
    value: 0,
    label: '男'
  }, {
    value: 1,
    label: '女'
  }]
}
