<template>
  <div style="height: 100%;">
    <div v-if="clearMenu"><router-view/></div>
    <el-container v-else style="height: 100%;border: 1px solid #eee">
      <el-aside :width="asideWidth" v-if="!$isEmpty(menu)" style="background-color: rgb(238, 241, 246)">
        <el-menu
          :default-active="activeIndex"
          @select="handleSelect"
          unique-opened
          text-color="#3C3F41"
          active-text-color="black"
          :collapse="isCollapse">
          <el-menu-item index="0" @click="setPerson()">
            <i class="el-icon-setting"></i>
            <span slot="title">个人信息修改</span>
          </el-menu-item>
          <el-submenu :index="index" v-for="(item,index) in menu" :key="index">
            <template slot="title">
              <i :class="item.icon" v-if="item.icon"></i>
              <span slot="title">{{$t(item.title)}}</span>
            </template>
            <el-menu-item v-for="(item1, index1) in item.subMenu" :key="index + index1" :index="index + index1" @click="$router.push(item1.route)">
              <i :class="item1.icon" v-if="item1.icon"></i>
              <span slot="title">{{$t(item1.title)}}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header style="text-align: right; font-size: 12px; margin: 12px; height: 32px;">
          <el-radio-group v-model="isCollapse" size="mini" style="margin-left: 12px; margin-right: 12px;">
            <el-radio-button :label="false">展开</el-radio-button>
            <el-radio-button :label="true">收起</el-radio-button>
          </el-radio-group>
          <el-select v-model="$i18n.locale" placeholder="请选择" @change="changeLanguage" size="mini">
            <el-option
              v-for="item in langOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span v-if="userInfo.name" style="margin-left: 12px; margin-right: 12px;">{{$t('normal.hello')}}{{userInfo.name}}</span>
          <el-button @click="logout" v-if="userInfo.name" size="small">{{$t('button.logout')}}</el-button>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { mixin } from '@/mixins/index'

export default {
  mixins: [mixin()],
  name: 'app',
  data () {
    return {
      isCollapse: false,
      activeIndex: '-0',
      asideWidth: '200px',
      langOption: [{
        label: '简体中文',
        value: 'zh'
      }, {
        label: 'English',
        value: 'en'
      }],
      clearMenu: false
    }
  },
  watch: {
    '$route': function (a, b, c) {
      if (['/chinese_papers', '/papers', '/setPerson'].includes(a.path)) {
        this.clearMenu = true
      } else {
        this.clearMenu = false
      }
    },
    'isCollapse': function (a, b, c) {
      if (a) {
        this.asideWidth = '0px'
      } else {
        this.asideWidth = '200px'
      }
    }
  },
  methods: {
    setPerson () {
      this.$router.push('/setPerson')
    },
    handleSelect (key, keyPath) {
    },
    changeLanguage (value) {
      Cookies.set('lang', value)
      this.$store.dispatch('changeLang', value)
    },
    logout () {
      this.$http.get('/api/auth/logout').then(response => {
        this.$router.replace({ path: '/login' })
        this.$store.dispatch('clearUser')
      })
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ]),
    ...mapGetters([
      'menu'
    ])
  },
  async created () {
    const response = await this.getFetch('/api/auth/getUser')
    await this.$store.dispatch('setUserInfo', response.userInfo)
    await this.$store.dispatch('setMenu', response.menu)
  }
}
</script>

<style lang="css">
*{
  padding:0;
  margin:0;
  box-sizing:border-box;
}
@import url('//at.alicdn.com/t/font_932767_hoa8emqp9ze.css');
@font-face {
  font-family: 'iconfont';  /* project id 932767 */
  src: url('//at.alicdn.com/t/font_932767_j5hokdqx9sn.eot');
  src: url('//at.alicdn.com/t/font_932767_j5hokdqx9sn.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_932767_j5hokdqx9sn.woff') format('woff'),
  url('//at.alicdn.com/t/font_932767_j5hokdqx9sn.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_932767_j5hokdqx9sn.svg#iconfont') format('svg');
}
.iconfont{
  margin-right: 10px;
  font-size: 18px;
  color: #909399;
}
i{
  font-family:'iconfont';
  font-style: normal;
  font-size:20px;
}
html,body, body>div.whole{
  height:100%;
}
.tabheaders{
  background: #F7F7F7 !important;
}
</style>

<style scope lang="css">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
