import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage],
    addPath: {},
    filters: [{
      value: 'lesson_id',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'course.money',
      value: 'money',
      rewrite: true,
      pk: 'lesson_pay_id'
    }],
    buttonItems: [{
      buttonName: 'course.user',
      type: 'route',
      path: '/lesson_pay/getUser?id=%lesson_pay_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/lesson_pay/delete?id=%lesson_pay_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'course.money',
      value: 'money',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.money' }, trigger: 'blur' }
      ]
    }, {
      value: 'lesson_id',
      type: 'hidden'
    }]
  },
  setUser: {
    permission: [p.schoolManage]
  },
  getUser: {
    permission: [p.schoolManage]
  }
}
