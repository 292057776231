import Vue from 'vue'
import 'vue-event-calendar/dist/style.css'
import vueEventCalendar from 'vue-event-calendar'
import Cookies from 'js-cookie'

let locale = Cookies.get('lang')
if (!locale) {
  locale = 'zh'
}

Vue.use(vueEventCalendar, { locale })
