export default {
  0: 'ok',
  1: 'access denied',
  2: 'need Login',
  3: 'server error',
  /* 全局通用 */
  10001: 'the method need get a page',
  10002: 'edit failed',
  10003: 'delete failed',
  /* 用户相关 */
  20001: '用户未注册',
  20002: '用户禁止登陆',
  20003: '密码错误',
  20004: '图片验证码错误',
  20005: '注册失败',
  20007: '登录失败',
  20008: '用户未登录',
  20009: '登录过期',
  20010: '用户账号异常',
  /* 代理相关 */
  30001: '尚未成为代理',
  30002: '代理不存在',
  30003: '认领成功',
  30004: '该体系已有上级',
  30005: '认领失败',
  30006: '暂未通过申请',
  30007: '已通过申请',
  30008: '已拒绝申请',
  30009: '审核失败',
  30010: '等级过低, 无法购买',
  30011: '此卷不存在',
  30012: '选货未完成',
  30013: '入场券购买成功',
  30014: '入场券购买失败',
  30015: '选货金额不足',
  30016: '选货完成',
  30017: '选货失败',
  30018: '选货已完成',
  30019: '添加地址失败',
  30020: '添加地址成功',
  30021: '删除地址失败',
  30022: '删除地址成功',
  30023: '修改地址失败',
  30024: '修改地址成功',
  30025: '换货金额不足',
  30026: '代理库存不足',
  30027: '已提交换货申请',
  30028: '换货申请提交失败',
  30029: '云仓库存不足',
  30030: '出货失败',
  30031: '出货成功',
  30032: '进货失败',
  30033: '进货成功',
  /* 资金相关 */
  40001: '充值成功',
  40002: '充值失败',
  40003: '余额不足',
  /* 商城相关 */
  50001: '数量不能减为0',
  50002: '未找到该商品',
  50003: '删除失败'
}
