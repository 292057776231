import './plugins/fastclick'
import Vue from 'vue'
import './plugins/defined'
import './plugins/vueResource'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './plugins/element'
import './plugins/calendar'
import './plugins/vueLocalStorge'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
