import { p, status } from '@/plugins/permission'
import { mixin } from '@/mixins/index'

export default {
  index: {
    permission: [p.schoolManage, p.teacher, p.finance, p.busManage],
    addPath: {
      text: 'button.add',
      display: [p.schoolManage, p.finance]
    },
    addButton: [{
      text: 'button.download',
      type: 'download',
      display: [p.schoolManage]
    }],
    filters: [{
      label: 'normal.class',
      value: 'class_id',
      multiple: true,
      option: 'classes'
    }, {
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'student.roomCode',
      value: 'room_code',
      default: ''
    }, {
      label: 'normal.state',
      value: 'state',
      multiple: true,
      option: status.student
    }, {
      label: 'student.teacherName',
      value: 'teacher_name',
      default: '',
      display: [p.schoolManage]
    }, {
      label: 'normal.gender',
      value: 'gender',
      multiple: true,
      option: status.gender
    }, {
      label: 'normal.time',
      value: 'came_at',
      type: 'datetime'
    }, {
      label: 'student.feeAt',
      value: 'fee_at',
      type: 'datetime'
    }, {
      label: 'student.updateAt',
      value: 'update_at',
      type: 'datetime'
    }],
    tableItems: [{
      label: 'normal.id',
      value: 'student_id',
      width: 63
    }, {
      label: 'normal.name',
      value: 'name',
      rewrite: [p.schoolManage, p.finance],
      pk: 'student_id',
      width: 63,
      fixed: true
    }, {
      label: 'normal.class',
      value: 'class_id',
      rewrite: [p.schoolManage],
      pk: 'student_id',
      option: 'classes',
      width: 77,
      fixed: true
    }, {
      label: 'student.teacherName',
      value: 'username'
    }, {
      label: 'student.roomCode',
      value: 'room_code',
      rewrite: [p.schoolManage],
      pk: 'student_id',
      width: 66
    }, {
      label: 'student.cameFrom',
      value: 'came_from',
      rewrite: [p.schoolManage, p.finance],
      pk: 'student_id',
      display: [p.schoolManage, p.finance]
    }, {
      label: 'normal.parents',
      value: 'real_name',
      width: 159,
      display: [p.schoolManage, p.finance]
    }, {
      label: 'normal.tel',
      value: 'tel',
      width: 159,
      display: [p.schoolManage, p.finance]
    }, {
      label: 'student.cameAt',
      value: 'came_at',
      type: 'datetime',
      rewrite: [p.schoolManage, p.teacher],
      pk: 'student_id',
      width: 159
    }, {
      label: 'student.feeAt',
      value: 'fee_at',
      type: 'datetime',
      width: 159,
      display: [p.schoolManage, p.finance]
    }, {
      label: 'normal.tuition',
      value: 'tuition',
      type: 'datetime',
      display: [p.schoolManage, p.finance],
      width: 68
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rewrite: [p.schoolManage, p.teacher],
      pk: 'student_id',
      display: [p.schoolManage, p.teacher],
      width: 169
    }, {
      label: 'student.desc',
      value: 'desc',
      rewrite: [p.schoolManage],
      pk: 'student_id',
      display: [p.schoolManage, p.finance],
      width: 300
    }, {
      label: 'student.isLiveIn',
      value: 'is_live_in',
      option: status.boolen,
      display: [p.schoolManage, p.teacher],
      rewrite: [p.schoolManage, p.teacher],
      pk: 'student_id',
      width: 36
    }, {
      label: 'normal.address',
      value: 'adr',
      display: [p.schoolManage],
      rewrite: [p.schoolManage],
      pk: 'student_id'
    }, {
      label: 'normal.birthday',
      value: 'birthday',
      display: [p.schoolManage],
      width: 99
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.student,
      rewrite: [p.schoolManage],
      pk: 'student_id',
      width: 49
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender,
      rewrite: [p.schoolManage],
      pk: 'student_id',
      width: 35
    }],
    buttonWidth: res => {
      if (mixin().methods.permissionCheck([2], res)) {
        return 273
      } else if (mixin().methods.permissionCheck([10], res)) {
        return 177
      } else if (mixin().methods.permissionCheck([11], res)) {
        return 177
      } else {
        return 177
      }
    },
    buttonItems: [{
      buttonName: 'button.up',
      type: 'ajax',
      path: '/api/student/up?student_id=%student_id%',
      showIf: function (key) {
        if (key.state === 0) {
          return true
        }
        return false
      },
      display: [p.schoolManage]
    }, {
      buttonName: 'button.down',
      type: 'ajax',
      path: '/api/student/down?student_id=%student_id%',
      showIf: key => {
        if (key.state === 1) {
          return true
        }
        return false
      },
      display: [p.schoolManage]
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/student/edit?id=%student_id%',
      showIf: key => { return true },
      display: [p.schoolManage, p.teacher]
    }, {
      buttonName: 'student.relationship',
      type: 'route',
      path: '/wechat_user_student/index?student_id=%student_id%&name=%name%',
      showIf: key => { return true },
      display: [p.schoolManage, p.teacher, p.finance]
    }, {
      buttonName: 'studentFee.index',
      type: 'route',
      path: '/student_fee/index?student_id=%student_id%&name=%name%',
      showIf: key => { return true },
      display: [p.schoolManage, p.finance]
    }, {
      buttonName: 'studentRoad.index',
      type: 'route',
      path: '/student_road/index?student_id=%student_id%&name=%name%',
      showIf: key => { return true },
      display: [p.schoolManage, p.busManage]
    // }, {
    //   buttonName: '点评',
    //   type: 'route',
    //   path: '/student_rate/getValue?student_id=%student_id%',
    //   showIf: key => { return true },
    //   display: [p.teacher]
    }]
  },
  add: {
    permission: [p.schoolManage, p.teacher, p.teacher],
    formItems: [{
      label: 'normal.class',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      option: 'classes'
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rule: 'identityId'
    }]
  },
  edit: {
    permission: [p.schoolManage, p.teacher, p.teacher],
    formItems: [{
      label: 'normal.class',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      option: 'classes',
      display: [p.schoolManage, p.teacher]
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rule: 'identityId'
    }]
  }
}
