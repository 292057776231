import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage],
    addPath: {},
    filters: [{
      label: 'normal.tel',
      value: 'tel',
      default: ''
    }, {
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime',
      default: ''
    }, {
      label: 'normal.state',
      value: 'state',
      multiple: true,
      option: status.user
    }],
    tableItems: [{
      label: 'normal.tel',
      value: 'tel',
      rewrite: true,
      pk: 'user_id',
      width: 112
    }, {
      label: 'normal.name',
      value: 'name',
      rewrite: true,
      pk: 'user_id',
      width: 72
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rewrite: true,
      pk: 'user_id',
      width: 170
    }, {
      label: 'normal.area',
      value: 'province|city'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.user,
      width: 50
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender,
      width: 50
    }],
    buttonItems: [{
      buttonName: 'button.up',
      type: 'ajax',
      path: '/api/user/up?user_id=%user_id%',
      showIf: function (key) {
        if (key.state === 0) {
          return true
        }
        return false
      }
    }, {
      buttonName: 'button.down',
      type: 'ajax',
      path: '/api/user/down?user_id=%user_id%',
      showIf: key => {
        if (key.state === 1) {
          return true
        }
        return false
      }
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/user/edit?id=%user_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'normal.permission',
      type: 'route',
      path: '/user_permission/edit?id=%user_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.tel',
      value: 'tel',
      rule: 'tel'
    }, {
      label: 'normal.password',
      value: 'password',
      rule: 'password',
      type: 'password'
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.bank_card',
      value: 'bank_card'
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rule: 'identityId'
    }]
  },
  edit: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.tel',
      value: 'tel',
      rule: 'tel'
    }, {
      label: 'normal.password',
      value: 'password',
      type: 'password'
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.bank_card',
      value: 'bank_card',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.bank_card' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.identity_id',
      value: 'identity_id',
      rule: 'identityId'
    }]
  }
}
