import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.salerManage, p.teacher, p.schoolManage],
    filters: [{
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.tel',
      value: 'tel',
      default: ''
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender
    }, {
      label: 'normal.school',
      value: 'from_school'
    }, {
      label: 'normal.bothday',
      value: 'birthday'
    }, {
      label: 'orderList.father_name',
      value: 'f_name'
    }, {
      label: 'orderList.father_work',
      value: 'work_unit'
    }, {
      label: 'orderList.father_tel',
      value: 'tel'
    }, {
      label: 'orderList.mother_name',
      value: 'spouse_name'
    }, {
      label: 'orderList.mother_work',
      value: 'spouse_work_unit'
    }, {
      label: 'orderList.mother_tel',
      value: 'spouse_tel'
    }, {
      label: 'normal.address',
      value: 'address'
    }, {
      label: 'normal.time',
      value: 'created_at'
    }],
    buttonItems: [{
      buttonName: 'button.detail',
      type: 'route',
      path: '/order_list/detail?id=%order_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'orderList.telError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.pushPool',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/pushPool?id=%order_id%',
      showIf: key => {
        return !(key.user_id === 0 && key.state === 1)
      },
      display: [p.salerManage, p.schoolManage]
    }, {
      buttonName: 'orderList.popPool',
      type: 'contentAjax',
      inputTitle: 'normal.teacher',
      path: '/api/order_list/popPool?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage, p.schoolManage]
    }]
  },
  add: {
    permission: [p.salerManage],
    formItems: [{
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender,
      default: 0
    }, {
      label: 'normal.school',
      value: 'school',
      default: ''
    }, {
      label: 'normal.bothday',
      value: 'bothday',
      default: ''
    }, {
      label: 'orderList.father_name',
      value: 'father_name',
      default: ''
    }, {
      label: 'orderList.father_work',
      value: 'father_work',
      default: ''
    }, {
      label: 'orderList.father_tel',
      value: 'father_tel',
      default: ''
    }, {
      label: 'orderList.mother_name',
      value: 'mother_name',
      default: ''
    }, {
      label: 'orderList.mother_work',
      value: 'mother_work',
      default: ''
    }, {
      label: 'orderList.mother_tel',
      value: 'mother_tel',
      default: ''
    }, {
      label: 'normal.address',
      value: 'family_address',
      default: ''
    }]
  },
  detail: {
    permission: [p.teacher, p.salerManage, p.schoolManage],
    buttonItems: [{
      buttonName: 'orderList.telError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.pushPool',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/pushPool?id=%order_id%',
      showIf: key => {
        return !(key.user_id === 0 && key.state === 1)
      },
      display: [p.salerManage]
    }, {
      buttonName: 'orderList.popPool',
      type: 'contentAjax',
      inputTitle: 'normal.teacher',
      path: '/api/order_list/popPool?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage]
    }]
  },
  statistic: {
    permission: [p.teacher, p.salerManage, p.schoolManage]
  }
}
