import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage],
    addButton: [{
      path: '/api/teacher_pay/index',
      text: 'normal.download',
      type: 'download',
      component: response => {
        let word = '\ufeff'
        word += '名字,基本工资,全员育人,主课,特色课,早读,口算,畅游书海,午读,早操,课间操,教案,作业,班主任,副班主任,外出津贴,全勤,校车,值班,其它\n'
        for (let i = 0; i < response.data.length; i++) {
          word += response.data[i].name + ',' + response.data[i].base_pay + ',' + response.data[i].teacher_pay + ','
          word += response.data[i].major_class + ',' + response.data[i].special_class + ',' + response.data[i].early_class + ','
          word += response.data[i].calculate_class + ',' + response.data[i].night_class + ',' + response.data[i].midday_class + ','
          word += response.data[i].early_done + ',' + response.data[i].middle_done + ',' + response.data[i].lesson_plan + ','
          word += response.data[i].home_work + ',' + response.data[i].classroom + ',' + response.data[i].vice_classroom + ','
          word += response.data[i].out_pay + ',' + response.data[i].duty_pay + ',' + response.data[i].school_bus + ','
          word += response.data[i].on_duty + ',' + response.data[i].other_pay + '\n'
        }
        return word
      },
      display: [p.schoolManage, p.finance]
    }, {
      path: '/api/teacher_pay/create',
      text: 'normal.refresh',
      type: 'ajax'
    }],
    filters: [{
      label: 'normal.time',
      value: 'pay_date',
      type: 'date',
      pickerType: 'month'
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'name',
      width: 66
    }, {
      label: 'teacherPay.basePay',
      value: 'base_pay',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.teacherPay',
      value: 'teacher_pay',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 50
    }, {
      label: 'teacherPay.majorClass',
      value: 'major_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.specialClass',
      value: 'special_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.earlyClass',
      value: 'early_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.calculateClass',
      value: 'calculate_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.nightClass',
      value: 'night_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 50
    }, {
      label: 'teacherPay.middayClass',
      value: 'midday_class',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.earlyDone',
      value: 'early_done',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.middleDone',
      value: 'middle_done',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.lessonPlan',
      value: 'lesson_plan',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.homeWork',
      value: 'home_work',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.classroom',
      value: 'classroom',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.viceClassroom',
      value: 'vice_classroom',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.outPay',
      value: 'out_pay',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 50
    }, {
      label: 'teacherPay.dutyPay',
      value: 'duty_pay',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 46
    }, {
      label: 'teacherPay.schoolBus',
      value: 'school_bus',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.onDuty',
      value: 'on_duty',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'teacherPay.otherPay',
      value: 'other_pay',
      rewrite: [p.schoolManage],
      pk: 'pay_id',
      width: 55
    }, {
      label: 'normal.total',
      value: 'teacher_date',
      component: (row) => {
        let all = 0
        for (let item in row) {
          if (item === 'pay_id') continue
          if (typeof (row[item]) === 'number') {
            all += row[item]
          }
        }
        return all
      },
      width: 55
    }, {
      label: 'normal.time',
      value: 'teacher_date',
      component: (row) => {
        return row.teacher_date.substring(0, 7)
      }
    }]
  }
}
