import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.support],
    addPath: {},
    filters: [],
    tableItems: [{
      label: 'store.name',
      value: 'name',
      rewrite: true,
      pk: 'store_id'
    }]
  },
  add: {
    permission: [p.support],
    formItems: [{
      label: 'store.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'school.zhSchoolName' }, trigger: 'blur' }
      ]
    }]
  }
}
