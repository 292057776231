import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.support],
    addPath: {},
    filters: [{
      label: 'store.name',
      value: 'store_id',
      option: 'store'
    }],
    tableItems: [{
      label: 'store.name',
      value: 'store_id',
      rewrite: true,
      pk: 'category_id',
      option: 'store'
    }, {
      label: 'store.cateName',
      value: 'cate_name',
      rewrite: true,
      pk: 'category_id'
    }]
  },
  add: {
    permission: [p.support],
    formItems: [{
      label: 'store.name',
      value: 'store_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.name' }, trigger: 'blur' }
      ],
      option: 'store'
    }, {
      label: 'store.cateName',
      value: 'cate_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.cate_name' }, trigger: 'blur' }
      ]
    }]
  }
}
