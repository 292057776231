import { p } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const user = []
const leader = []

export default {
  index: {
    permission: [p.schoolManage],
    addPath: {},
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.class',
      value: 'class_id',
      option: 'classes'
    }, {
      label: 'teacher.leader',
      value: 'leader_id',
      option: [],
      options: leader,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          leader.splice(0, leader.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            leader.push(res.data.data[i])
          }
        }
      }
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.lesson',
      value: 'title'
    }, {
      label: 'normal.teacher',
      value: 'leader_name'
    }, {
      label: 'normal.class',
      value: 'class_id',
      option: 'classes',
      rewrite: [p.schoolManage],
      pk: 'class_id'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/teacher/edit?id=%teacher_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.class',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      option: 'classes',
      display: [p.schoolManage]
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson|?teacher=1',
      display: [p.schoolManage]
    }, {
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.teacher',
      value: 'leader_id',
      option: leader,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          leader.splice(0, leader.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            leader.push(res.data.data[i])
          }
        }
      }
    }]
  },
  edit: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.class',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      option: 'classes',
      display: [p.schoolManage]
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson|?teacher=1',
      display: [p.schoolManage]
    }, {
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.teacher',
      value: 'leader_id',
      option: leader,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.class' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          leader.splice(0, leader.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            leader.push(res.data.data[i])
          }
        }
      }
    }]
  }
}
