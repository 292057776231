import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
import { messages } from '@/lang/index.js'
import Cookies from 'js-cookie'

Vue.use(VueI18n)

let locale = Cookies.get('lang')
if (!locale) {
  locale = 'zh'
}
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale,
  messages
})

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

export { i18n }
