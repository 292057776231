import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.manage],
    addPath: {},
    filters: [{
      label: 'scoreReason.enReason',
      value: 'en_reason',
      default: ''
    }, {
      label: 'scoreReason.zhReason',
      value: 'zh_reason',
      default: ''
    }, {
      label: 'normal.category',
      value: 'category_id',
      option: 'score_category'
    }],
    tableItems: [{
      label: 'scoreReason.enReason',
      value: 'en_reason',
      rewrite: true,
      pk: 'reason_id'
    }, {
      label: 'scoreReason.zhReason',
      value: 'zh_reason',
      rewrite: true,
      pk: 'reason_id'
    }, {
      label: 'normal.score',
      value: 'score',
      rewrite: true,
      pk: 'reason_id'
    }, {
      label: 'normal.category',
      value: 'category_id',
      option: 'score_category',
      rewrite: true,
      pk: 'reason_id'
    }]
  },
  add: {
    permission: [p.manage],
    formItems: [{
      label: 'scoreReason.enReason',
      value: 'en_reason',
      default: ''
    }, {
      label: 'scoreReason.zhReason',
      value: 'zh_reason',
      default: ''
    }, {
      label: 'normal.score',
      value: 'score',
      default: 0
    }, {
      label: 'normal.category',
      value: 'category_id',
      option: 'score_category'
    }]
  }
}
