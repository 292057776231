import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const user = []

export default {
  index: {
    permission: [p.schoolManage, p.teacherManage],
    addPath: {},
    addButton: [{
      path: '/api/course/getTeacherSCV',
      text: 'course.downloadClass',
      type: 'download',
      component: response => {
        let word = '\ufeff'
        let classList = {}
        for (let i = 0; i < response.length; i++) {
          if (!classList[response[i].class_name]) {
            classList[response[i].class_name] = {}
          }
          if (!classList[response[i].class_name][response[i].course_list]) {
            classList[response[i].class_name][response[i].course_list] = {}
          }
          if (!classList[response[i].class_name][response[i].course_list][response[i].week_day]) {
            classList[response[i].class_name][response[i].course_list][response[i].week_day] = []
          }
          classList[response[i].class_name][response[i].course_list][response[i].week_day].push(response[i])
        }
        for (let i in classList) {
          word += i + '\n' + '课节,星期日,星期一,星期二,星期三,星期四,星期五,星期六\n'
          for (let j in classList[i]) {
            word += j + ','
            for (let k = 0; k < 7; k++) {
              if (classList[i][j][k]) {
                for (let x = 0; x < classList[i][j][k].length; x++) {
                  word += classList[i][j][k][x].lesson_name + classList[i][j][k][x].loop_class
                  if (classList[i][j][k].length > x + 1) {
                    word += '|'
                  } else {
                    word += ','
                  }
                }
              } else {
                word += ','
              }
            }
            word += '\n'
          }
        }
        return word
      },
      display: [p.schoolManage, p.finance, p.teacherManage]
    }, {
      path: '/api/course/getTeacherSCV',
      text: 'course.downloadTeacher',
      type: 'download',
      component: response => {
        let word = '\ufeff'
        let classList = {}
        for (let i = 0; i < response.length; i++) {
          if (!classList[response[i].teacher_name]) {
            classList[response[i].teacher_name] = {}
          }
          if (!classList[response[i].teacher_name][response[i].course_list]) {
            classList[response[i].teacher_name][response[i].course_list] = {}
          }
          if (!classList[response[i].teacher_name][response[i].course_list][response[i].week_day]) {
            classList[response[i].teacher_name][response[i].course_list][response[i].week_day] = []
          }
          classList[response[i].teacher_name][response[i].course_list][response[i].week_day].push(response[i])
        }
        for (let i in classList) {
          word += i + '\n' + '课节,星期日,星期一,星期二,星期三,星期四,星期五,星期六\n'
          for (let j in classList[i]) {
            word += j + ','
            for (let k = 0; k < 7; k++) {
              if (classList[i][j][k]) {
                for (let x = 0; x < classList[i][j][k].length; x++) {
                  word += classList[i][j][k][x].class_name + classList[i][j][k][x].lesson_name + classList[i][j][k][x].loop_class
                  if (classList[i][j][k].length > x + 1) {
                    word += '|'
                  } else {
                    word += ','
                  }
                }
              } else {
                word += ','
              }
            }
            word += '\n'
          }
        }
        return word
      },
      display: [p.schoolManage, p.finance, p.teacherManage]
    }, {
      path: '/api/course/checkAll',
      text: 'course.check',
      type: 'popup',
      component: response => {
        let checkClass = ''
        for (let i = 0; i < response.checkClass.length; i++) {
          if (response.checkClass[i].num > 1) {
            let loop = []
            let loopClass = response.checkClass[i].loop_class.split(',')
            for (let j = 0; j < loopClass.length; j++) {
              loop.push(loopClass[j].split(''))
            }
            if (loop.length > 1) {
              let length = loop[0].length
              for (let j = 1; j < loop.length; j++) {
                if (length !== loop[j].length) {
                  checkClass += '未对应的循环规则' + response.checkClass[i].class_name + '|星期' + response.checkClass[i].week_day + '第' + response.checkClass[i].course_list + '节课<br>'
                  break
                }
              }
              let checkValue = []
              for (let j = 0; j < loop.length; j++) {
                for (let x = 0; x < loop[j].length; x++) {
                  if (!checkValue[x]) checkValue[x] = 0
                  checkValue[x] += Number(loop[j][x])
                }
              }
              for (let j = 0; j < checkValue.length; j++) {
                if (checkValue[j] !== 1) {
                  checkClass += '循环规则设置出错' + response.checkClass[i].class_name + '|星期' + response.checkClass[i].week_day + '第' + response.checkClass[i].course_list + '节课<br>'
                }
              }
            } else {
              checkClass += response.checkClass[i].class_name + '|星期' + response.checkClass[i].week_day + '第' + response.checkClass[i].course_list + '节课<br>'
            }
          } else if (response.checkClass[i].loop_class !== '') {
            checkClass += '额外的循环规则' + response.checkClass[i].loop_class + '|' + response.checkClass[i].class_name + '|星期' + response.checkClass[i].week_day + '第' + response.checkClass[i].course_list + '节课<br>'
          }
        }
        let checkTeacher = ''
        for (let i = 0; i < response.checkTeacher.length; i++) {
          if (response.checkTeacher[i].num > 1) {
            const loopClass = response.checkTeacher[i].loop_class.split(',')
            let sub = 0
            for (let j = 0; j < loopClass.length; j++) {
              sub += Number(loopClass[j])
            }
            let num = ''
            for (let j = 0; j < response.checkTeacher[i].num; j++) {
              num += '1'
            }
            if (Number(num) !== sub) {
              checkTeacher += response.checkTeacher[i].name + '|星期' + response.checkTeacher[i].week_day + '第' + response.checkTeacher[i].course_list + '节课<br>'
            }
          }
        }

        let message = ''
        if (checkClass) {
          message += '班级课程问题<br>' + checkClass
        }
        if (checkTeacher) {
          message += '老师课程重复<br>' + checkTeacher
        }
        if (!message) {
          message += '检查通过'
        }
        return {
          title: '检查结果',
          dangerouslyUseHTMLString: true,
          message,
          duration: 0
        }
      },
      display: [p.schoolManage, p.teacherManage]
    }, {
      path: '/course/create',
      text: 'course.create',
      type: 'route',
      display: [p.schoolManage, p.teacherManage]
    }, {
      path: '/course/list',
      text: 'course.classPre',
      type: 'route',
      display: [p.schoolManage]
    }, {
      path: '/course/teacherList',
      text: 'course.realClass',
      type: 'route',
      display: [p.schoolManage]
    }, {
      path: '/teacher_pay/index',
      text: 'course.payTip',
      type: 'route',
      display: [p.schoolManage]
    }],
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson'
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      option: status.weekDay
    }, {
      value: 'state',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'teacher_name'
    }, {
      label: 'class.className',
      value: 'class_name'
    }, {
      label: 'normal.lesson',
      value: 'lesson_name'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      option: status.weekDay
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info'
    }, {
      label: 'course.courseTime',
      value: 'course_time',
      option: status.courseTime
    }, {
      label: 'course.loopClass',
      value: 'loop_class'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/course/edit?id=%course_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/course/delete?id=%course_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage, p.teacherManage],
    formItems: [{
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '删除',
            value: 0
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'class.className' }, trigger: 'blur' }
      ],
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.weekDay' }, trigger: 'blur' }
      ],
      option: status.weekDay
    }, {
      label: 'course.courseList',
      value: 'course_list',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.courseList' }, trigger: 'blur' }
      ],
      option: 'course_info'
    }, {
      label: 'course.courseTime',
      value: 'course_time',
      option: status.courseTime,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.courseTime' }, trigger: 'blur' }
      ]
    }, {
      label: 'course.loopClass',
      value: 'loop_class',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'course.loopClass' }, trigger: 'blur' }
      ]
    }]
  },
  edit: {
    permission: [p.schoolManage, p.teacherManage],
    formItems: [{
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '删除',
            value: 0
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'class.className' }, trigger: 'blur' }
      ],
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.weekDay' }, trigger: 'blur' }
      ],
      option: status.weekDay
    }, {
      label: 'course.courseList',
      value: 'course_list',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.courseList' }, trigger: 'blur' }
      ],
      option: 'course_info'
    }, {
      label: 'course.courseTime',
      value: 'course_time',
      option: status.courseTime,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'course.courseTime' }, trigger: 'blur' }
      ]
    }, {
      label: 'course.loopClass',
      value: 'loop_class',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'course.loopClass' }, trigger: 'blur' }
      ]
    }]
  },
  create: {
    permission: [p.schoolManage, p.teacherManage]
  },
  list: {
    getDataUrl: 'course/getList',
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson'
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      option: status.weekDay
    }, {
      value: 'state',
      type: 'hidden'
    }],
    permission: [p.schoolManage, p.teacherManage]
  },
  teacherList: {
    getDataUrl: 'teacher_lesson/getList',
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson'
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      option: status.weekDay
    }, {
      value: 'state',
      type: 'hidden'
    }, {
      label: 'normal.time',
      value: 'study_at',
      type: 'datetime'
    }],
    permission: [p.schoolManage, p.teacherManage]
  },
  teacherPay: {
    getDataUrl: 'teacher_lesson/create',
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'class.className',
      value: 'class_id',
      option: 'classes'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson'
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info'
    }, {
      label: 'course.weekDay',
      value: 'week_day',
      option: status.weekDay
    }, {
      value: 'state',
      type: 'hidden'
    }, {
      label: 'normal.time',
      value: 'study_at',
      type: 'datetime'
    }],
    permission: [p.schoolManage, p.teacherManage]
  }
}
