import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
const student = []
const user = []

export default {
  index: {
    permission: [p.support, p.schoolManage, p.teacher],
    filters: [{
      label: 'normal.time',
      value: 'item_name',
      type: 'datetime'
    }, {
      label: 'store.itemName',
      value: 'item_id',
      option: 'store_item'
    }],
    tableItems: [{
      label: 'store.serialNumber',
      value: 'serial_number',
      width: 169
    }, {
      label: 'store.username',
      value: 'name'
    }, {
      label: 'store.state',
      value: 'state',
      option: status.store
    }, {
      label: 'store.name',
      value: 'store_id',
      option: 'store'
    }, {
      label: 'store.cateName',
      value: 'category_id',
      option: 'store_category'
    }, {
      label: 'store.itemName',
      value: 'item_id',
      option: 'store_item'
    }, {
      label: 'normal.number',
      value: 'num'
    }, {
      label: 'normal.price',
      value: 'price'
    }]
  },
  add: {
    permission: [p.support, p.teacher],
    formItems: [{
      label: 'store.content',
      value: 'content',
      default: ''
    }, {
      label: 'store.itemName',
      value: 'item_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.itemName' }, trigger: 'blur' }
      ],
      type: 'cascader',
      cascaderOption: 'store_item'
    }, {
      label: 'normal.student',
      value: 'student_id',
      default: '',
      option: student,
      url: '/api/student/getSomeOne',
      filterable: true,
      canDelete: true,
      remoteMethod: async q => {
        if (q) {
          student.splice(0, student.length)
          const res = await Vue.http.get('/api/student/getSomeOne?label=' + q + '&class=1')
          for (let i = 0; i < res.data.data.length; i++) {
            student.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.name',
      value: 'teacher_id',
      default: '',
      option: user,
      filterable: true,
      canDelete: true,
      url: '/api/user/getSomeOne',
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.price',
      value: 'price',
      type: 'number',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.price' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.number',
      value: 'num',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.number' }, trigger: 'blur' }
      ]
    }, {
      label: 'store.state',
      value: 'state',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'store.state' }, trigger: 'blur' }
      ],
      option: status.store
    }]
  }
}
