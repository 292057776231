import { p } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
const student = []
const teacher = []

export default {
  index: {
    permission: [p.schoolManage, p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.student',
      value: 'student_id',
      option: [],
      options: student,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          student.splice(0, student.length)
          const res = await Vue.http.get('/api/student/getSomeOne?label=' + q)
          if (res.data.data.length > 0) {
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          }
        }
      }
    }, {
      label: 'normal.teacher',
      value: 'user_id',
      option: [],
      options: teacher,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          teacher.splice(0, teacher.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          if (res.data.data.length > 0) {
            teacher.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              teacher.push(res.data.data[i])
            }
          }
        }
      },
      display: [p.schoolManage]
    }, {
      label: 'normal.reason',
      value: 'reason_id',
      option: 'score_reason'
    }],
    tableItems: [{
      label: 'normal.time',
      value: 'created_at'
    }, {
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.teacher',
      value: 'teacher_name'
    }, {
      label: 'normal.studentCode',
      value: 'student_code'
    }, {
      label: 'normal.reason',
      value: 'reason_id',
      option: 'score_reason'
    }, {
      label: 'normal.score',
      value: 'score'
    }]
  },
  add: {
    permission: [p.schoolManage, p.teacher],
    formItems: [{
      label: 'normal.student',
      value: 'student_id',
      option: student,
      url: '/api/student/getSomeOne',
      filterable: true,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.student' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          student.splice(0, student.length)
          const res = await Vue.http.get('/api/student/getSomeOne?label=' + q + '&class=1')
          for (let i = 0; i < res.data.data.length; i++) {
            student.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.reason',
      value: 'reason_id',
      option: 'score_reason'
    }]
  }
}
