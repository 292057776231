import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.teacherManage],
    addPath: {},
    filters: [],
    tableItems: [{
      label: 'normal.title',
      value: 'title',
      rewrite: true,
      pk: 'lesson_id'
    }, {
      label: 'lesson.isGroup',
      value: 'is_group',
      option: status.boolen,
      rewrite: true,
      pk: 'lesson_id'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/lesson/edit?id=%lesson_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/lesson/delete?id=%lesson_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'course.money',
      type: 'route',
      path: '/lesson_pay/index?lesson_id=%lesson_id%',
      display: [p.schoolManage],
      showIf: key => { return true }
    }, {
      buttonName: 'lesson.lessonUser',
      type: 'route',
      path: '/lesson_user/index?lesson_id=%lesson_id%',
      display: [p.schoolManage],
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage, p.teacherManage],
    formItems: [{
      label: 'normal.title',
      value: 'title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.content',
      value: 'content',
      type: 'editor'
    }, {
      label: 'lesson.isGroup',
      value: 'is_group',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'lesson.isGroup' }, trigger: 'blur' }
      ],
      option: status.boolen
    }]
  },
  edit: {
    permission: [p.schoolManage, p.teacherManage],
    formItems: [{
      label: 'normal.title',
      value: 'title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.content',
      value: 'content',
      type: 'editor'
    }, {
      label: 'lesson.isGroup',
      value: 'is_group',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'lesson.isGroup' }, trigger: 'blur' }
      ],
      option: status.boolen
    }]
  }
}
