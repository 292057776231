import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.busManage],
    addPath: {},
    tableItems: [{
      label: 'studentRoad.road',
      value: 'road_name',
      rewrite: true,
      pk: 'road_id'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/road/delete?id=%road_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'studentRoad.user',
      type: 'route',
      path: '/road_user/index?road_id=%road_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage, p.busManage],
    formItems: [{
      label: 'studentRoad.road',
      value: 'road_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.road' }, trigger: 'blur' }
      ]
    }]
  }
}
