export default {
  state: [{
    value: 1,
    label: '正常'
  }, {
    value: 2,
    label: '禁用'
  }],
  boolen: [{
    value: 0,
    label: '否'
  }, {
    value: 1,
    label: '是'
  }],
  user: [{
    value: 0,
    label: 'ban'
  }, {
    value: 1,
    label: '正常'
  }],
  identity: [{
    value: 1,
    label: '管理员'
  }, {
    value: 2,
    label: '学校管理员'
  }, {
    value: 3,
    label: '老师'
  }, {
    value: 4,
    label: '家长'
  }],
  gender: [{
    value: 0,
    label: '男'
  }, {
    value: 1,
    label: '女'
  }],
  student: [{
    value: 1,
    label: '在校'
  }, {
    value: 2,
    label: '毕业'
  }, {
    value: 3,
    label: '转校'
  }, {
    value: 4,
    label: '休学'
  }, {
    value: 5,
    label: '退学'
  }, {
    value: 6,
    label: '新增'
  }, {
    value: 7,
    label: '交费'
  }],
  resume: [{
    value: 1,
    label: '新上传'
  }, {
    value: 2,
    label: '可以面试'
  }, {
    value: 3,
    label: '可以笔试'
  }, {
    value: 4,
    label: '录取'
  }, {
    value: 5,
    label: '不录取'
  }, {
    value: 6,
    label: '简历未通过'
  }],
  resume_post: [{
    value: 1,
    label: '正常'
  }, {
    value: 2,
    label: '暂时不招聘'
  }],
  store: [{
    value: 1,
    label: '入库'
  }, {
    value: 2,
    label: '出库'
  }, {
    value: 3,
    label: '报损'
  }, {
    value: 4,
    label: '折旧'
  }, {
    value: 5,
    label: '退还'
  }, {
    value: 6,
    label: '撤销'
  }],
  source: [{
    value: 1,
    label: '人教版'
  }, {
    value: 2,
    label: '苏教版'
  }],
  qtype: [{
    value: 1,
    label: '选择题'
  }, {
    value: 2,
    label: '判断题'
  }, {
    value: 3,
    label: '填空题'
  }, {
    value: 4,
    label: '问答题'
  }],
  stype: [{
    value: 1,
    label: '文字'
  }, {
    value: 2,
    label: '图片'
  }],
  orderList: [{
    value: 1,
    label: '待处理'
  }, {
    value: 2,
    label: '待访问'
  }, {
    value: 3,
    label: '待支付'
  }, {
    value: 4,
    label: '成单'
  }, {
    value: 5,
    label: '空号错号或不能接通'
  }, {
    value: 6,
    label: '约访失败'
  }, {
    value: 7,
    label: '取消到访'
  }, {
    value: 8,
    label: '推迟到访'
  }, {
    value: 9,
    label: '到访后拒绝'
  }, {
    value: 10,
    label: '人工取消'
  }, {
    value: 11,
    label: '自动取消'
  }],
  orderListComeIn: [{
    value: 1,
    label: '幼儿园'
  }, {
    value: 2,
    label: '400电话'
  }, {
    value: 3,
    label: '微信公众平台'
  }, {
    value: 4,
    label: '直访'
  }],
  grade: [{
    value: 1,
    label: '一年级'
  }, {
    value: 2,
    label: '二年级'
  }, {
    value: 3,
    label: '三年级'
  }, {
    value: 4,
    label: '四年级'
  }, {
    value: 5,
    label: '五年级'
  }, {
    value: 6,
    label: '六年级'
  }],
  examType: [{
    value: 1,
    label: '作业'
  }, {
    value: 2,
    label: '考试'
  }],
  watchChart: [{
    value: ',2,',
    label: '校领导'
  }, {
    value: ',2,10,',
    label: '校领导、班主任'
  }, {
    value: ',2,6,',
    label: '校领导、网站管理'
  }, {
    value: ',2,6,10,',
    label: '校领导、网站管理、班主任'
  }],
  writeChart: [{
    value: '',
    label: '所有人'
  }, {
    value: ',4,',
    label: '家长'
  }, {
    value: ',3,',
    label: '老师'
  }],
  chartStatus: [{
    value: 1,
    label: '正常'
  }, {
    value: 2,
    label: '停止'
  }],
  dataType: [{
    value: 'text',
    label: '文字'
  }, {
    value: 'picker',
    label: '选项'
  }, {
    value: 'textarea',
    label: '大段文字'
  }, {
    value: 'number',
    label: '数字'
  }],
  questionFrom: [{
    value: 1,
    label: '教科书'
  }, {
    value: 2,
    label: '练习册'
  }, {
    value: 3,
    label: '同类扩展'
  }, {
    value: 4,
    label: '拔高扩展'
  }],
  courseTime: [{
    value: 600,
    label: '10分钟'
  }, {
    value: 1200,
    label: '20分钟'
  }, {
    value: 1800,
    label: '30分钟'
  }, {
    value: 2400,
    label: '40分钟'
  }, {
    value: 3600,
    label: '60分钟'
  }, {
    value: 7200,
    label: '120分钟'
  }],
  weekDay: [{
    value: 0,
    label: '星期日'
  }, {
    value: 1,
    label: '星期一'
  }, {
    value: 2,
    label: '星期二'
  }, {
    value: 3,
    label: '星期三'
  }, {
    value: 4,
    label: '星期四'
  }, {
    value: 5,
    label: '星期五'
  }, {
    value: 6,
    label: '星期六'
  }],
  studentFee: [{
    value: 1,
    label: '转账'
  }, {
    value: 2,
    label: 'pos微信'
  }, {
    value: 3,
    label: 'pos支付宝'
  }, {
    value: 4,
    label: '银联储蓄卡'
  }, {
    value: 5,
    label: '银联信用卡'
  }, {
    value: 6,
    label: '现金'
  }, {
    value: 7,
    label: '张总微信'
  }, {
    value: 8,
    label: '张总支付宝'
  }, {
    value: 9,
    label: '张总卡'
  }],
  family: [{
    value: '父亲',
    label: '父亲'
  }, {
    value: '母亲',
    label: '母亲'
  }, {
    value: '爷爷',
    label: '爷爷'
  }, {
    value: '奶奶',
    label: '奶奶'
  }, {
    value: '姥姥',
    label: '姥姥'
  }, {
    value: '姥爷',
    label: '姥爷'
  }],
  roadWay: [{
    value: 1,
    label: '不坐'
  }, {
    value: 2,
    label: '来校'
  }, {
    value: 3,
    label: '回家'
  }, {
    value: 4,
    label: '来回'
  }],
  scheduleCourse: [{
    value: 1,
    label: '已提交'
  }, {
    value: 2,
    label: '同意'
  }, {
    value: 3,
    label: '无效'
  }, {
    value: 4,
    label: '已落实'
  }],
  permission: [{
    value: 2,
    label: '校管理员'
  }, {
    value: 3,
    label: '老师'
  }, {
    value: 4,
    label: 'HR'
  }, {
    value: 5,
    label: '后勤'
  }, {
    value: 6,
    label: '网站'
  }, {
    value: 7,
    label: '采购'
  }, {
    value: 8,
    label: '保安'
  }, {
    value: 9,
    label: '招生'
  }, {
    value: 10,
    label: '班主任'
  }, {
    value: 11,
    label: '财务'
  }, {
    value: 12,
    label: '校车管理员'
  }, {
    value: 13,
    label: '办公室'
  }, {
    value: 14,
    label: '教务'
  }, {
    value: 15,
    label: '教学'
  }],
  schoolGroup: [{
    value: 1,
    label: '教务组'
  }, {
    value: 2,
    label: '班级组'
  }, {
    value: 3,
    label: '其它组'
  }],
  visitor: [{
    value: '1',
    label: '来访'
  }, {
    value: '2',
    label: '返校'
  }, {
    value: '3',
    label: '寻人'
  }],
  courseChange: [{
    value: 1,
    label: '待审核'
  }, {
    value: 2,
    label: '待调课'
  }, {
    value: 3,
    label: '请假完成'
  }, {
    value: 4,
    label: '不准请假'
  }, {
    value: 5,
    label: '不准调课'
  }],
  courseWay: [{
    value: 1,
    label: '请假'
  }, {
    value: 2,
    label: '调课'
  }],
  customer: [{
    value: 1,
    label: '正常'
  }, {
    value: 2,
    label: '错误'
  }, {
    value: 3,
    label: '无意向'
  }, {
    value: 4,
    label: '保留'
  }, {
    value: 5,
    label: '新入库'
  }]
}
