import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.HR, p.schoolManage],
    addPath: {
      display: [p.HR]
    },
    filters: [],
    tableItems: [{
      label: 'normal.post',
      value: 'post_name',
      rewrite: true,
      pk: 'item_id'
    }, {
      label: 'normal.state',
      value: 'state',
      rewrite: true,
      pk: 'item_id',
      option: status.resume_post
    }],
    buttonItems: []
  },
  add: {
    permission: [p.HR],
    formItems: [{
      label: 'normal.post',
      value: 'post_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.post' }, trigger: 'blur' }
      ]
    }]
  }
}
