import { p } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const article = []
const category = []

export default {
  index: {
    permission: [p.web],
    addPath: {},
    filters: [{
      label: 'article.title',
      value: 'article_id',
      option: [],
      options: article,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          article.splice(0, article.length)
          const res = await Vue.http.get('/api/article/getSomeOne?label=' + q)
          article.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            article.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'articleCategory.name',
      value: 'category_id',
      option: [],
      options: category,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          category.splice(0, category.length)
          const res = await Vue.http.get('/api/article_category/getSomeOne?label=' + q)
          category.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            category.push(res.data.data[i])
          }
        }
      }
    }],
    tableItems: [{
      label: 'article.title',
      value: 'title'
    }, {
      label: 'articleCategory.name',
      value: 'category_name'
    }, {
      label: 'normal.sort',
      value: 'sort',
      pk: 'id',
      rewrite: true
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/article_b/edit?id=%id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.web],
    formItems: [{
      label: 'article.title',
      value: 'article_id',
      option: article,
      filterable: true,
      url: '/api/article/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'article.title' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          article.splice(0, article.length)
          const res = await Vue.http.get('/api/article/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            article.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'articleCategory.name',
      value: 'category_id',
      option: category,
      filterable: true,
      url: '/api/article_category/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          category.splice(0, category.length)
          const res = await Vue.http.get('/api/article_category/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            category.push(res.data.data[i])
          }
        }
      }
    }]
  },
  edit: {
    permission: [p.web],
    formItems: [{
      label: 'article.title',
      value: 'article_id',
      option: article,
      filterable: true,
      url: '/api/article/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'article.title' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          article.splice(0, article.length)
          const res = await Vue.http.get('/api/article/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            article.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'articleCategory.name',
      value: 'category_id',
      option: category,
      filterable: true,
      url: '/api/article_category/getSomeOne',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          category.splice(0, category.length)
          const res = await Vue.http.get('/api/article_category/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            category.push(res.data.data[i])
          }
        }
      }
    }]
  }
}
