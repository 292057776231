import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [],
    tableItems: [{
      label: 'normal.title',
      value: 'book_title',
      rewrite: true,
      pk: 'book_id'
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/textbook/edit?id=%book_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.add',
      type: 'route',
      path: '/textbook/delete?id=%book_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/textbook/delete?id=%book_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.teacher],
    formItems: [{
      label: 'normal.title',
      value: 'book_title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson'
    }]
  },
  edit: {
    permission: [p.teacher],
    formItems: [{
      label: 'normal.title',
      value: 'book_title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.lesson' }, trigger: 'blur' }
      ],
      option: 'lesson'
    }]
  }
}
