import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.desc',
      value: 'question_id',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'normal.score',
      value: 'score',
      width: 68
    }, {
      label: 'question.diff',
      value: 'diff',
      width: 68
    }, {
      label: 'question.anwser',
      value: 'answer',
      width: 268
    }, {
      label: 'normal.content',
      value: 'item_content'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/question_item/edit?id=%item_id%&question_id=%question_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/question_item/delete?id=%item_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'knowledge.index',
      type: 'route',
      path: '/question_knowledge/index?question_id=%question_id%&item_id=%item_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.teacher],
    focus: true
  },
  edit: {
    permission: [p.teacher],
    focus: true
  }
}
