function propValue () {
  return {
    data () {
      return {
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick (picker) {
              var time = new Date(new Date().toLocaleDateString()).getTime()
              var start = new Date(time).toString()
              var end = new Date(time + 24 * 60 * 60 * 1000 - 1).toString()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        }
      }
    }
  }
}

function mixin () {
  return {
    methods: {
      getFetch (url, payload, needSuccess) {
        if (payload === undefined) {
          payload = {}
        } else {
          const params = {}
          for (let o in payload) {
            if (this.$isEmpty(payload[o]) && payload[o] !== 0) continue
            params[o] = payload[o]
          }
          payload = { params }
        }
        return new Promise((resolve) => {
          this.$http.get(url, payload).then(response => {
            if (typeof (response.data) === 'object') {
              if (response.data.errno > 0) {
                if (response.data.errno === 2) {
                  if (!['/login'].includes(this.$route.path)) {
                    this.$router.replace({ path: '/login' })
                  }
                } else {
                  this.$message.error(this.$t('error.' + response.data.errno))
                }
                return false
              }
              if (needSuccess) {
                this.$message(this.$t('error.0'))
              }
              if (response.data.data.resWord) {
                this.$message(response.data.data.resWord)
              }
              resolve(response.data.data)
            } else {
              resolve(response)
            }
          }, response => {
            this.$message.error(this.$t('error.3'))
          })
        })
      },
      postFetch (url, data, option) {
        return new Promise((resolve) => {
          this.$http.post(url, data, { emulateJSON: true }).then(response => {
            if (typeof (response.data) === 'object') {
              if (response.data.errno > 0) {
                if (response.data.errno === 2) {
                  if (!['/login'].includes(this.$route.path)) {
                    this.$store.dispatch('clearUser')
                    this.$router.replace({ path: '/login' })
                  }
                } else {
                  this.$message.error(this.$t('error.' + response.data.errno))
                  if (option && option.needError) {
                    resolve(response.data)
                  }
                }
                return false
              }
              if (option && option.needSuccess) {
                this.$message(this.$t('error.0'))
              }
              if (response.data.data.resWord) {
                this.$message(response.data.data.resWord)
              }
              resolve(response.data.data)
            } else {
              resolve(response)
            }
          }, response => {
            this.$message.error(this.$t('error.3'))
          })
        })
      },
      async permissionCheck (permission, userInfo) {
        if (!permission) {
          return true
        }
        if (!userInfo) {
          if (this.userInfo.userId === 0) {
            const response = await this.getFetch('/api/auth/getUser')
            await this.$store.dispatch('setUserInfo', response.userInfo)
            await this.$store.dispatch('setMenu', response.menu)
          }
          userInfo = this.userInfo
        }
        for (let i = 0; i < userInfo.identity.length; i++) {
          if (permission.includes(userInfo.identity[i])) {
            return true
          }
        }
        return false
      }
    }
  }
}

export { mixin, propValue }
