import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
const student = []

export default {
  index: {
    permission: [p.schoolManage, p.finance],
    addPath: {
      text: 'button.add',
      display: [p.schoolManage, p.finance]
    },
    addButton: [{
      path: '/api/student_fee/reportAll',
      text: 'button.finance',
      type: 'download',
      display: [p.schoolManage, p.finance]
    }, {
      text: 'button.download',
      type: 'download',
      display: [p.schoolManage, p.finance]
    }],
    filters: [{
      label: 'normal.name',
      value: 'student_id',
      option: [],
      options: student,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          if (Number(q) > 0) {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?value=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          } else {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?label=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          }
        }
      }
    }, {
      label: 'student.feeAt',
      value: 'created_at',
      type: 'datetime'
    }, {
      label: 'studentFee.receiptNum',
      value: 'receipt_num'
    }, {
      label: 'studentFee.feeType',
      value: 'fee_type',
      option: status.studentFee
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'class.className',
      value: 'class_name'
    }, {
      label: 'studentFee.feeType',
      value: 'fee_type',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id',
      option: status.studentFee
    }, {
      label: 'studentFee.tuition',
      value: 'tuition',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id'
    }, {
      label: 'studentFee.fee',
      value: 'fee',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id'
    }, {
      label: 'studentFee.content',
      value: 'content',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id'
    }, {
      label: 'studentFee.receiptNum',
      value: 'receipt_num',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id'
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime',
      rewrite: [p.schoolManage, p.finance],
      pk: 'fee_id'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/student_fee/delete?id=%fee_id%',
      showIf: function (key) { return true },
      display: [p.schoolManage, p.finance]
    }]
  },
  add: {
    permission: [p.schoolManage, p.finance],
    formItems: [{
      label: 'studentFee.feeType',
      value: 'fee_type',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentFee.feeType' }, trigger: 'blur' }
      ],
      option: status.studentFee
    }, {
      label: 'studentFee.tuition',
      value: 'tuition',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentFee.tuition' }, trigger: 'blur' }
      ]
    }, {
      label: 'studentFee.fee',
      value: 'fee',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentFee.fee' }, trigger: 'blur' }
      ]
    }, {
      label: 'studentFee.content',
      value: 'content'
    }, {
      label: 'studentFee.receiptNum',
      value: 'receipt_num'
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.time' }, trigger: 'blur' }
      ]
    }, {
      value: 'student_id',
      type: 'hidden',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.time' }, trigger: 'blur' }
      ]
    }]
  }
}
