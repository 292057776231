import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import vueResource from 'vue-resource'
Vue.use(vueResource)

let locale = Cookies.get('lang')
if (!locale) {
  locale = 'zh'
}
const userInfo = {
  userId: 0,
  identity: -1,
  name: ''
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo,
    menu: [],
    lang: locale,
    options: {},
    cascaderOptions: {}
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = { ...state.userInfo, ...payload }
    },
    setMenu (state, payload) {
      state.menu = payload
    },
    clearUser (state) {
      state.userInfo = {}
      state.menu = []
    },
    changeLang (state, payload) {
      state.lang = payload
    },
    setOption (state, payload) {
      state.options = { ...state.options, ...payload }
    },
    setCascaderOption (state, payload) {
      state.cascaderOptions = { ...state.cascaderOptions, ...payload }
    }
  },
  actions: {
    setUserInfo ({ commit }, payload) {
      commit('setUserInfo', payload)
    },
    setMenu ({ commit }, payload) {
      commit('setMenu', payload)
    },
    clearUser ({ commit }) {
      commit('clearUser')
    },
    changeLang ({ commit }, payload) {
      commit('changeLang', payload)
    },
    async getOption ({ commit, state }, payload) {
      if (!state.options[payload]) {
        let expand = ''
        let first = payload
        if (payload.indexOf('|') !== -1) {
          const o = payload.split('|')
          expand = o[1]
          first = o[0]
        }
        let url = '/api/' + first + '/label' + expand
        if (['school', 'score_reason'].includes(first)) {
          url += '?lang=' + state.lang
        }
        const res = await Vue.http.get(url)
        state.options[payload] = res.data.data
        commit('setOption', state.options)
        return state.options[payload]
      } else {
        return state.options[payload]
      }
    },
    async getCascaderOption ({ commit, state }, payload) {
      if (!state.cascaderOptions[payload]) {
        let expand = ''
        let first = payload
        if (payload.indexOf('|') !== -1) {
          const o = payload.split('|')
          expand = o[1]
          first = o[0]
        }
        let url = '/api/' + first + '/cascaderLabel' + expand
        const res = await Vue.http.get(url)
        state.cascaderOptions[payload] = res.data.data
        commit('setCascaderOption', state.cascaderOptions)
        return state.cascaderOptions[payload]
      } else {
        return state.cascaderOptions[payload]
      }
    }
  },
  getters: {
    menu: (state, getters) => {
      return state.menu
    }
  }
})
