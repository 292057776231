import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.salerManage, p.teacher, p.schoolManage],
    addPath: {
      text: 'normal.add',
      url: '/order_list/addOrder',
      display: [p.salerManage, p.teacher]
    },
    filters: [{
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.teacher',
      value: 'username',
      default: ''
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.orderList
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'username',
      display: [p.salerManage, p.schoolManage]
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.orderList
    }, {
      label: 'normal.name',
      value: 'name',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'normal.gender',
      value: 'gender',
      rewrite: [p.salerManage],
      pk: 'order_id',
      option: status.gender
    }, {
      label: 'normal.school',
      value: 'school',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.nowLevel',
      value: 'now_level',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'normal.bothday',
      value: 'bothday',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.father_name',
      value: 'father_name',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.father_work',
      value: 'father_work',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.father_tel',
      value: 'father_tel',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.mother_name',
      value: 'mother_name',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.mother_work',
      value: 'mother_work',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'orderList.mother_tel',
      value: 'mother_tel',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'normal.address',
      value: 'family_address',
      rewrite: [p.salerManage],
      pk: 'order_id'
    }, {
      label: 'normal.time',
      value: 'created_at'
    }, {
      label: 'orderList.comeIn',
      value: 'come_in',
      option: status.orderListComeIn,
      rewrite: [p.salerManage],
      pk: 'order_id'
    }],
    buttonItems: [{
      buttonName: 'button.detail',
      type: 'route',
      path: '/order_list/detail?id=%order_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'orderList.telError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return (key.state === 2 || key.state === 8) && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return (key.state === 2 || key.state === 8) && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return (key.state === 2 || key.state === 8) && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.pushPool',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/pushPool?id=%order_id%',
      showIf: key => {
        return !(key.user_id === 0 && key.state === 1)
      },
      display: [p.salerManage, p.schoolManage]
    }, {
      buttonName: 'orderList.popPool',
      type: 'contentAjax',
      inputTitle: 'normal.teacher',
      path: '/api/order_list/popPool?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage, p.schoolManage]
    }, {
      buttonName: 'orderList.delete',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delete?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage, p.schoolManage]
    }, {
      buttonName: 'orderList.abandon',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/outList?id=%order_id%',
      showIf: key => {
        return [6, 7, 9].includes(key.state) && key.is_out === 1
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.save',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/save?id=%order_id%',
      showIf: key => {
        return key.state !== 1 && key.userInfo.userId === key.user_id && key.is_out === 1
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.unsave',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/unsave?id=%order_id%',
      showIf: key => {
        return key.state !== 1 && key.userInfo.userId === key.user_id && key.is_out === 3
      },
      display: [p.teacher]
    }]
  },
  addOrder: {
    permission: [p.salerManage, p.teacher],
    formItems: [{
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender,
      default: 0
    }, {
      label: 'normal.school',
      value: 'school',
      default: ''
    }, {
      label: 'orderList.nowLevel',
      value: 'now_level',
      default: ''
    }, {
      label: 'normal.bothday',
      value: 'bothday',
      default: ''
    }, {
      label: 'orderList.father_name',
      value: 'father_name',
      default: ''
    }, {
      label: 'orderList.father_work',
      value: 'father_work',
      default: ''
    }, {
      label: 'orderList.father_tel',
      value: 'father_tel',
      default: ''
    }, {
      label: 'orderList.mother_name',
      value: 'mother_name',
      default: ''
    }, {
      label: 'orderList.mother_work',
      value: 'mother_work',
      default: ''
    }, {
      label: 'orderList.mother_tel',
      value: 'mother_tel',
      default: ''
    }, {
      label: 'normal.address',
      value: 'family_address',
      default: ''
    }, {
      label: 'orderList.comeIn',
      value: 'come_in',
      option: status.orderListComeIn,
      default: 1
    }]
  },
  detail: {
    permission: [p.teacher, p.salerManage, p.schoolManage],
    buttonItems: [{
      buttonName: 'orderList.telError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.telConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 1 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeDelay',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delay?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.comeConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 2 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payError',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/cancel?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.payConfirm',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/confirm?id=%order_id%',
      showIf: key => {
        return key.state === 3 && key.userInfo.userId === key.user_id
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.pushPool',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/pushPool?id=%order_id%',
      showIf: key => {
        return !(key.user_id === 0 && key.state === 1)
      },
      display: [p.salerManage]
    }, {
      buttonName: 'orderList.popPool',
      type: 'contentAjax',
      inputTitle: 'normal.teacher',
      path: '/api/order_list/popPool?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage, p.teacher]
    }, {
      buttonName: 'orderList.delete',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/delete?id=%order_id%',
      showIf: key => { return true },
      display: [p.salerManage, p.schoolManage]
    }, {
      buttonName: 'orderList.abandon',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/outList?id=%order_id%',
      showIf: key => {
        return [6, 7, 9].includes(key.state) && key.is_out === 1
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.save',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/save?id=%order_id%',
      showIf: key => {
        return key.state !== 1 && key.userInfo.userId === key.user_id && key.is_out === 1
      },
      display: [p.teacher]
    }, {
      buttonName: 'orderList.unsave',
      type: 'contentAjax',
      inputTitle: 'orderList.inputReason',
      path: '/api/order_list/unsave?id=%order_id%',
      showIf: key => {
        return key.state !== 1 && key.userInfo.userId === key.user_id && key.is_out === 3
      },
      display: [p.teacher]
    }]
  },
  statistic: {
    permission: [p.teacher, p.salerManage, p.schoolManage]
  },
  checkList: {
    permission: [p.schoolManage]
  }
}
