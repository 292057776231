import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      value: 'question_id',
      type: 'hidden'
    }, {
      value: 'item_id',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'normal.content',
      value: 'knowledge_id',
      option: 'knowledge',
      rewrite: true,
      pk: 'id'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/question_knowledge/delete?id=%id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.teacher],
    formItems: [{
      value: 'question_id',
      type: 'hidden'
    }, {
      value: 'item_id',
      type: 'hidden'
    }, {
      label: 'knowledge.index',
      value: 'knowledge_id',
      option: 'knowledge',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'knowledge.index' }, trigger: 'blur' }
      ]
    }]
  },
  edit: {
    permission: [p.teacher],
    focus: true
  }
}
