import Cookies from 'js-cookie'
import zhStatus from '@/lang/zh_status'
import enStatus from '@/lang/en_status'

let locale = Cookies.get('lang')
if (!locale) {
  locale = 'zh'
}
let status = zhStatus
switch (locale) {
  case 'zh':
    status = zhStatus
    break
  case 'en':
    status = enStatus
    break
}

const p = {
  manage: 1,
  schoolManage: 2,
  teacher: 3,
  HR: 4,
  support: 5,
  web: 6,
  headmaster: 7,
  guard: 8,
  salerManage: 9,
  finance: 11,
  busManage: 12,
  office: 13,
  teacherManage: 14
}

export { p, status }
