import Vue from 'vue'
import Router from 'vue-router'
import routers from '@/routers'

const routes = []
for (let key in routers) {
  for (let v in routers[key]) {
    if (['index', 'add', 'edit'].includes(v) && !routers[key][v].focus) {
      routes.push({
        path: '/' + key + '/' + v,
        name: key + v,
        component: resolve => require(['@/views/pages/' + v + '.vue'], resolve),
        props: routers[key][v]
      })
    } else {
      routes.push({
        path: '/' + key + '/' + v,
        name: key + v,
        component: resolve => require(['@/views/' + key + '/' + v + '.vue'], resolve),
        props: routers[key][v]
      })
    }
  }
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/index',
      name: '首页',
      component: resolve => require(['@/views/index.vue'], resolve)
    },
    {
      path: '/login',
      name: '登录',
      component: resolve => require(['@/views/login.vue'], resolve)
    },
    {
      path: '/setPerson',
      name: '个人',
      component: resolve => require(['@/views/setPerson.vue'], resolve)
    },
    {
      path: '/papers',
      name: '获取试卷',
      component: resolve => require(['@/views/papers.vue'], resolve)
    },
    {
      path: '/chinese_papers',
      name: '获取试卷',
      component: resolve => require(['@/views/chinese_papers.vue'], resolve)
    },
    {
      path: '*',
      name: '/index',
      redirect: '/index'
    },
    ...routes
  ]
})
