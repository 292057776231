import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const user = []

export default {
  index: {
    permission: [p.schoolManage, p.teacherManage],
    filters: [{
      label: 'normal.name',
      value: 'user_id',
      option: [],
      options: user,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '全部'
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.scheduleCourse
    }, {
      label: 'normal.time',
      value: 'study_at',
      type: 'date'
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name',
      width: 66
    }, {
      label: 'normal.desc',
      value: 'desc'
    }, {
      label: 'normal.class',
      value: 'class_name',
      width: 78
    }, {
      label: 'normal.time',
      value: 'study_at',
      width: 100
    }, {
      label: 'course.courseList',
      value: 'course_list',
      option: 'course_info',
      width: 93
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson',
      width: 93
    }, {
      label: 'course.oldTitle',
      value: 'old_lesson_id',
      option: 'lesson',
      width: 93
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.scheduleCourse,
      width: 66
    }],
    buttonItems: [{
      buttonName: 'button.check',
      type: 'ajax',
      path: '/api/schedule_course/check?id=%scd_crs_id%',
      showIf: function (key) {
        if (key.state === 1) {
          return true
        }
        return false
      }
    }, {
      buttonName: 'button.agree',
      type: 'ajax',
      path: '/api/schedule_course/agree?id=%scd_crs_id%',
      showIf: function (key) {
        if (key.state === 1) {
          return true
        }
        return false
      }
    }, {
      buttonName: 'button.disagree',
      type: 'ajax',
      path: '/api/schedule_course/disagree?id=%scd_crs_id%',
      showIf: key => {
        if (key.state === 1) {
          return true
        }
        return false
      }
    }, {
      buttonName: 'button.forceAgree',
      type: 'ajax',
      path: '/api/schedule_course/forceAgree?id=%scd_crs_id%',
      showIf: key => {
        if (key.state === 1) {
          return true
        }
        return false
      }
    }]
  }
}
