import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    filters: [{
      value: 'exam_item_id',
      type: 'hidden'
    }],
    addPath: {},
    tableItems: [{
      label: 'normal.title',
      value: 'title'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/examination_question/delete?id=%exam_q_id%'
    }]
  },
  add: {
    permission: [p.teacher],
    focus: true
  }
}
