import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.support, p.schoolManage],
    addPath: {},
    tableItems: [{
      label: 'normal.title',
      value: 'group_name',
      width: 169,
      rewrite: true,
      pk: 'group_id'
    }, {
      label: 'normal.type',
      value: 'group_type',
      option: status.schoolGroup,
      rewrite: true,
      pk: 'group_id'
    }],
    buttonItems: [{
      buttonName: 'schoolGroup.teacher',
      type: 'route',
      path: '/school_group_member/index?group_id=%group_id%',
      showIf: function (key) {
        return true
      }
    }]
  },
  add: {
    permission: [p.support, p.teacher],
    formItems: [{
      label: 'normal.title',
      value: 'group_name',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.type',
      value: 'group_type',
      option: status.schoolGroup,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.type' }, trigger: 'blur' }
      ]
    }]
  }
}
