import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.HR, p.headmaster],
    addPath: {
      text: 'normal.add',
      url: '/resume/addNew'
    },
    filters: [{
      label: 'normal.tel',
      value: 'tel',
      default: ''
    }, {
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.time',
      value: 'commitDate',
      type: 'datetime',
      default: ''
    }, {
      label: 'normal.post',
      value: 'post',
      option: 'resume_post'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.resume
    }],
    tableItems: [{
      label: 'normal.post',
      value: 'post',
      option: 'resume_post'
    }, {
      label: 'normal.tel',
      value: 'tel'
    }, {
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.identity_id',
      value: 'identity'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.resume
    }, {
      label: 'normal.gender',
      value: 'gender',
      option: status.gender
    }],
    buttonItems: [{
      buttonName: 'button.detail',
      type: 'route',
      path: '/resume/detail?id=%_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'resume.adjust',
      type: 'ajax',
      path: '/api/resume/sendMsg?id=%_id%',
      showIf: key => {
        return key.state === 1
      },
      display: [p.HR]
    }]
  },
  addNew: {
    permission: [p.schoolManage, p.HR]
  },
  detail: {
    permission: [p.schoolManage, p.HR, p.headmaster]
  }
}
