import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.support],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'normal.content',
      value: 'content'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/store_nothing/delete?id=%thing_id%',
      showIf: key => { return true }
    }]
  }
}
