'use strict'

import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

Vue.http.interceptors.push((request, next) => {
  if (request.methods === 'POST') {
    this.isLoading = true
  }
})
