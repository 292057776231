import validator from 'validator'
import isEmpty from 'is-empty'
import { i18n } from './element'

const validata = {
  tel: (rule, value, callback) => {
    if (isEmpty(value)) {
      callback(new Error(i18n.t('formRule.tel.required')))
    } else if (!validator.isMobilePhone(value, 'zh-CN')) {
      callback(new Error(i18n.t('formRule.tel.type')))
    } else {
      callback()
    }
  },
  email: (rule, value, callback) => {
    if (isEmpty(value)) {
      callback(new Error(i18n.t('formRule.email.required')))
    } else if (!validator.isEmail(value)) {
      callback(new Error(i18n.t('formRule.email.type')))
    } else {
      callback()
    }
  },
  password: (rule, value, callback) => {
    if (isEmpty(value)) {
      callback(new Error(i18n.t('formRule.password.required')))
    } else if (!validator.isLength(value, { min: 6, max: undefined })) {
      callback(new Error(i18n.t('formRule.password.length')))
    } else {
      callback()
    }
  },
  captcha: (rule, value, callback) => {
    if (isEmpty(value)) {
      callback(new Error(i18n.t('formRule.captcha.required')))
    } else if (!validator.isLength(value, { min: 4, max: 4 })) {
      callback(new Error(i18n.t('formRule.captcha.length')))
    } else {
      callback()
    }
  },
  identityId: (rule, value, callback) => {
    if (isEmpty(value)) {
      // callback(new Error(i18n.t('formRule.identityId.required')))
      callback()
    } else if (!/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
      callback(new Error(i18n.t('formRule.identityId.type')))
    } else {
      callback()
    }
  },
  getRules: (list) => {
    const rules = {}
    for (let i = 0; i < list.length; i++) {
      if (!isEmpty(validata[list[i].value])) {
        rules[list[i].labal] = [{ validator: validata[list[i].value], trigger: 'blur' }]
      } else if (typeof list[i].value === 'object') {
        const newV = []
        for (let v in list[i].value) {
          if (typeof list[i].value[v].message === 'object') {
            const msg = {}
            for (let o in list[i].value[v].message) {
              if (o === 'text') continue
              msg[o] = i18n.t(list[i].value[v].message[o])
            }
            newV.push({
              ...list[i].value[v],
              ...{ message: i18n.t(list[i].value[v].message.text, msg) }
            })
          } else {
            newV.push({
              ...list[i].value[v],
              ...{ message: i18n.t(list[i].value[v].message) }
            })
          }
        }
        rules[list[i].labal] = newV
      }
    }
    return rules
  }
}

export { validata, validator }
