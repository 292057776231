import { p } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

const user = []

export default {
  index: {
    permission: [p.schoolManage],
    addPath: {},
    filters: [{
      value: 'road_id',
      type: 'hidden'
    }],
    tableItems: [{
      label: 'knowledge.index',
      value: 'title'
    }, {
      label: 'normal.name',
      value: 'name'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/road_user/delete?id=%id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.name',
      value: 'user_id',
      option: user,
      filterable: true,
      url: '/api/user/getSomeOne',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          user.splice(0, user.length)
          const res = await Vue.http.get('/api/user/getSomeOne?label=' + q)
          user.push({
            label: '删除',
            value: 0
          })
          for (let i = 0; i < res.data.data.length; i++) {
            user.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.lesson',
      value: 'lesson_id',
      option: 'lesson',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.road' }, trigger: 'blur' }
      ]
    }]
  }
}
