import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [],
    tableItems: [{
      label: 'normal.content',
      value: 'text_content',
      rewrite: true,
      pk: 'text_id'
    }, {
      label: 'normal.sort',
      value: 'text_sort',
      rewrite: true,
      pk: 'text_id'
    }, {
      label: 'normal.grade',
      value: 'text_grade',
      option: status.grade,
      rewrite: true,
      pk: 'text_id'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/chinese_text/editSource?id=%text_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.teacher],
    formItems: [{
      label: 'normal.content',
      value: 'text_content',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.content' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.sort',
      value: 'text_sort',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.sort' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.grade',
      value: 'text_grade',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.grade' }, trigger: 'blur' }
      ],
      option: status.grade
    }]
  },
  editSource: {
    permission: [p.teacher]
  }
}
