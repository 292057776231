import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
const seat = []
const student = []

export default {
  index: {
    permission: [p.schoolManage, p.busManage, p.teacher],
    addPath: {
      text: 'button.add',
      display: [p.schoolManage, p.busManage, p.teacher]
    },
    filters: [{
      label: 'normal.name',
      value: 'student_id',
      option: [],
      options: student,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          if (Number(q) > 0) {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?value=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          } else {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?label=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          }
        }
      }
    }, {
      label: 'studentRoad.seat',
      value: 'seat_id',
      option: [],
      options: seat,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          if (Number(q) > 0) {
            seat.splice(0, seat.length)
            const res = await Vue.http.get('/api/road_seat/getSomeOne?label=' + q)
            seat.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              seat.push(res.data.data[i])
            }
          } else {
            seat.splice(0, seat.length)
            const res = await Vue.http.get('/api/road_seat/getSomeOne?label=' + q)
            seat.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              seat.push(res.data.data[i])
            }
          }
        }
      }
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'studentRoad.road',
      value: 'road_name'
    }, {
      label: 'studentRoad.seat',
      value: 'seat'
    }, {
      label: 'studentRoad.name',
      value: 'seat_name'
    }, {
      label: 'normal.monday',
      value: 'monday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.tuesday',
      value: 'tuesday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.wednesday',
      value: 'wednesday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.thursday',
      value: 'thursday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.friday',
      value: 'friday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.saturday',
      value: 'saturday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }, {
      label: 'normal.sunday',
      value: 'sunday',
      option: status.roadWay,
      rewrite: [p.schoolManage, p.busManage, p.teacher],
      pk: 'stu_road_id'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/student_road/edit?id=%stu_road_id%&student_id=%student_id%',
      showIf: key => { return true },
      display: [p.schoolManage, p.busManage, p.teacher]
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/student_road/delete?id=%stu_road_id%',
      showIf: function (key) { return true },
      display: [p.schoolManage, p.busManage, p.teacher]
    }]
  },
  add: {
    permission: [p.schoolManage, p.busManage, p.teacher],
    formItems: [{
      label: 'normal.student',
      value: 'student_id',
      option: student,
      url: '/api/student/getSomeOne',
      filterable: true,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.student' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          student.splice(0, student.length)
          const res = await Vue.http.get('/api/student/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            student.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'studentRoad.seat',
      value: 'seat_id',
      option: seat,
      url: '/api/road_seat/getSomeOne',
      filterable: true,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.seat' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          seat.splice(0, seat.length)
          const res = await Vue.http.get('/api/road_seat/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            seat.push(res.data.data[i])
          }
        }
      }
    }, {
      label: 'normal.monday',
      value: 'monday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.monday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.tuesday',
      value: 'tuesday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.tuesday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.wednesday',
      value: 'wednesday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.wednesday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.thursday',
      value: 'thursday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.thursday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.friday',
      value: 'friday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.friday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.saturday',
      value: 'saturday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.saturday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      label: 'normal.sunday',
      value: 'sunday',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.sunday' }, trigger: 'blur' }
      ],
      option: status.roadWay
    }, {
      value: 'student_id',
      type: 'hidden',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.time' }, trigger: 'blur' }
      ]
    }]
  },
  edit: {
    permission: [p.schoolManage, p.busManage, p.teacher],
    formItems: [{
      label: 'studentRoad.seat',
      value: 'seat_id',
      option: seat,
      url: '/api/road_seat/getSomeOne',
      filterable: true,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.seat' }, trigger: 'blur' }
      ],
      remoteMethod: async q => {
        if (q) {
          seat.splice(0, seat.length)
          const res = await Vue.http.get('/api/road_seat/getSomeOne?label=' + q)
          for (let i = 0; i < res.data.data.length; i++) {
            seat.push(res.data.data[i])
          }
        }
      }
    }]
  }
}
