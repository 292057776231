import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.manage],
    addPath: {},
    filters: [],
    tableItems: [{
      label: 'normal.category',
      value: 'category_name',
      rewrite: true,
      pk: 'category_id'
    }]
  },
  add: {
    permission: [p.manage],
    formItems: [{
      label: 'normal.category',
      value: 'category_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.category' }, trigger: 'blur' }
      ]
    }]
  },
  edit: {
    permission: [p.manage],
    formItems: [{
      label: 'normal.category',
      value: 'category_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.category' }, trigger: 'blur' }
      ]
    }]
  }
}
