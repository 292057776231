import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.web],
    addPath: {},
    filters: [{
      label: 'articleCategory.parentName',
      value: 'parent_id',
      option: 'article_category'
    }],
    tableItems: [{
      label: 'articleCategory.name',
      value: 'category_name',
      rewrite: true,
      pk: 'category_id'
    }, {
      label: 'articleCategory.parentName',
      value: 'parent_name'
    }, {
      label: 'normal.sort',
      value: 'sort',
      rewrite: true,
      pk: 'category_id'
    }, {
      label: 'articleCategory.linkUrl',
      value: 'link_url',
      rewrite: true,
      pk: 'category_id'
    }, {
      label: 'articleCategory.isHidden',
      value: 'is_hiden',
      rewrite: true,
      pk: 'category_id',
      option: status.boolen
    }],
    buttonItems: [{
      buttonName: 'button.detail',
      type: 'route',
      path: '/article_category/index?parent_id=%category_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/article_category/edit?id=%category_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.web],
    formItems: [{
      label: 'articleCategory.parentName',
      value: 'parent_id',
      option: 'article_category',
      default: 0
    }, {
      label: 'articleCategory.name',
      value: 'category_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'articleCategory.linkUrl',
      value: 'link_url',
      default: ''
    }, {
      value: 'user_id',
      type: 'hidden',
      default: 10
    }, {
      label: 'articleCategory.isHidden',
      value: 'is_hiden',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.isHidden' }, trigger: 'blur' }
      ],
      option: status.boolen,
      default: 0
    }]
  },
  edit: {
    permission: [p.web],
    formItems: [{
      label: 'articleCategory.parentName',
      value: 'parent_id',
      option: 'article_category'
    }, {
      label: 'articleCategory.name',
      value: 'category_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'articleCategory.linkUrl',
      value: 'link_url'
    }, {
      label: 'articleCategory.isHidden',
      value: 'is_hiden',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'articleCategory.isHidden' }, trigger: 'blur' }
      ],
      option: status.boolen
    }]
  }
}
