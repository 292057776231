import { p, status } from '@/plugins/permission'
import { dateFormat } from '@/plugins/helper'

export default {
  index: {
    filters: [{
      label: 'normal.tel',
      value: 'tel',
      default: ''
    }, {
      label: 'normal.name',
      value: 'name',
      default: ''
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime',
      default: ''
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'teacher',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.name',
      value: 'name',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.student',
      value: 'studentName',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.temperature',
      value: 'temperature',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.content',
      value: 'content',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.state',
      option: status.visitor,
      value: 'state',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.tel',
      value: 'tel',
      rewrite: [p.schoolManage],
      pk: '_id'
    }, {
      label: 'normal.time',
      value: 'created_at',
      component: (res) => {
        let data = dateFormat('YYYY-mm-dd HH:MM:SS', new Date(res.created_at.$date))
        if (res.esc_at) {
          data += '到' + dateFormat('YYYY-mm-dd HH:MM:SS', new Date(res.esc_at.$date))
        }
        return data
      }
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/visitor/delete?id=%_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.edit',
      type: 'route',
      path: '/visitor/edit?id=%_id%',
      showIf: key => { return true }
    }]
  },
  edit: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'normal.teacher',
      value: 'teacher'
    }, {
      label: 'normal.tel',
      value: 'tel',
      rule: 'tel'
    }, {
      label: 'normal.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' },
        { min: 2, max: 5, message: { text: 'formRule.normal.length', min: 3, max: 5 }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.temperature',
      value: 'temperature'
    }, {
      label: 'normal.student',
      value: 'studentName'
    }, {
      label: 'normal.content',
      value: 'content'
    }, {
      label: 'normal.state',
      value: 'state',
      option: status.visitor,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.state' }, trigger: 'blur' }
      ]
    }]
  }
}
