import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import { zhTr } from '@/lang/zh'
import { enTr } from '@/lang/en'

const messages = {
  en: {
    ...enTr,
    ...enLocale
  },
  zh: {
    ...zhTr,
    ...zhLocale
  }
}

export { messages }
