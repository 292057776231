import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.manage],
    addPath: {},
    filters: [{
      label: 'book.name',
      value: 'name'
    }],
    tableItems: [{
      label: 'book.bookNum',
      value: 'book_num',
      rewrite: true,
      pk: 'book_id'
    }, {
      label: 'book.author',
      value: 'author',
      rewrite: true,
      pk: 'book_id'
    }, {
      label: 'book.press',
      value: 'press',
      rewrite: true,
      pk: 'book_id'
    }, {
      label: 'book.wordage',
      value: 'wordage',
      rewrite: true,
      pk: 'book_id'
    }, {
      label: 'book.publicationDate',
      value: 'publication_date',
      rewrite: true,
      pk: 'book_id',
      type: 'datetime'
    }]
  },
  add: {
    permission: [p.manage],
    formItems: [{
      label: 'book.name',
      value: 'name',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.bookNum',
      value: 'book_num',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.bookNum' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.author',
      value: 'author',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.author' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.press',
      value: 'press',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.press' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.wordage',
      value: 'wordage',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.wordage' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.publicationDate',
      value: 'publication_date',
      type: 'datetime',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.publicationDate' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.url',
      value: 'url',
      type: 'image',
      limit: 1,
      default: []
    }]
  },
  edit: {
    permission: [p.manage],
    formItems: [{
      label: 'book.name',
      value: 'name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.bookNum',
      value: 'book_num',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.bookNum' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.author',
      value: 'author',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.author' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.press',
      value: 'press',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.press' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.wordage',
      value: 'wordage',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.wordage' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.publicationDate',
      value: 'publication_date',
      type: 'datetime',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'book.publicationDate' }, trigger: 'blur' }
      ]
    }, {
      label: 'book.url',
      value: 'url',
      type: 'image',
      limit: 1,
      default: []
    }]
  }
}
