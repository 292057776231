import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.type',
      value: 'exam_type',
      option: status.examType
    }, {
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime'
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'name'
    }, {
      label: 'normal.type',
      value: 'exam_type',
      option: status.examType
    }, {
      label: 'examination.upDiff',
      value: 'up_diff'
    }, {
      label: 'examination.downDiff',
      value: 'down_diff'
    }, {
      label: 'normal.time',
      value: 'created_at'
    }],
    buttonItems: [{
      buttonName: 'button.get',
      type: 'route',
      path: '/papers?id=%exam_id%'
    }, {
      buttonName: 'button.detail',
      type: 'route',
      path: '/examination_item/index?exam_id=%exam_id%'
    }, {
      buttonName: 'examination.class',
      type: 'route',
      path: '/examination/class_analyse?exam_id=%exam_id%'
    }]
  },
  add: {
    permission: [p.teacher],
    focus: true
  },
  edit: {
    permission: [p.teacher],
    focus: true
  },
  class_analyse: {
    permission: [p.teacher]
  }
}
