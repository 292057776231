import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage],
    filters: [{
      label: 'normal.teacher',
      value: 'name'
    }, {
      label: 'normal.teacher',
      value: 'state',
      default: 1
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'name'
    }, {
      label: 'normal.content',
      value: 'content'
    }, {
      label: 'teacherStudy.adjust',
      value: 'adjust'
    }, {
      label: 'teacherStudy.conten',
      value: 'pic',
      image: true
    }],
    buttonItems: [{
      buttonName: 'teacherStudy.adjust',
      type: 'contentAjax',
      inputTitle: 'teacherStudy.adjust',
      path: '/api/teacher_study/adjust?id=%study_id%',
      showIf: key => {
        return true
      }
    }]
  }
}
