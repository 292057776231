export default {
  0: 'ok',
  1: 'access denied',
  2: 'need Login',
  /* 全局通用 */
  10001: 'the method need get a page',
  10002: 'edit failed',
  10003: 'delete failed',
  10004: '数据库连接错误',
  10005: '不能重复',
  10006: '重复操作',
  /* 用户相关 */
  20001: '用户未注册',
  20002: '用户禁止登陆',
  20003: '密码错误',
  20004: '图片验证码错误',
  20005: '身份证错误',
  /* 考试 */
  30001: '未找到试卷',
  /* 招生 */
  40001: '未找到该老师',
  /* 题库 */
  50001: '已归档，不能修改',
  50002: '已经有这个知识点',
  /* 表单模板 */
  60001: '已归档，不能修改',
  60002: '已经有这个知识点',
  /* 表单模板 */
  70001: '没有权限',
  70002: '没有表单',
  /* 课程体系 */
  80001: '已经生成了课程'
}
