import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage],
    addPath: {},
    filters: [{
      label: 'class.className',
      value: 'class_name',
      default: ''
    }],
    tableItems: [{
      label: 'class.className',
      value: 'class_name',
      rewrite: true,
      pk: 'class_id'
    }],
    buttonItems: [{
      buttonName: 'normal.teacher',
      type: 'route',
      path: '/class_manage/index?class_id=%class_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage],
    formItems: [{
      label: 'class.className',
      value: 'class_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'class.className' }, trigger: 'blur' }
      ]
    }]
  }
}
