import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.manage],
    addPath: {},
    filters: [{
      label: 'school.zhSchoolName',
      value: 'zh_school_name',
      default: ''
    }, {
      label: 'school.enSchoolName',
      value: 'en_school_name',
      default: ''
    }],
    tableItems: [{
      label: 'school.zhSchoolName',
      value: 'zh_school_name',
      rewrite: true,
      pk: 'school_id'
    }, {
      label: 'school.enSchoolName',
      value: 'en_school_name',
      rewrite: true,
      pk: 'school_id'
    }]
  },
  add: {
    permission: [p.manage],
    formItems: [{
      label: 'school.zhSchoolName',
      value: 'zh_school_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'school.zhSchoolName' }, trigger: 'blur' },
        { min: 3, max: 5, message: { text: 'formRule.normal.length', max: 50 }, trigger: 'blur' }
      ]
    }, {
      label: 'school.enSchoolName',
      value: 'en_school_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'school.enSchoolName' }, trigger: 'blur' },
        { min: 3, max: 5, message: { text: 'formRule.normal.length', max: 50 }, trigger: 'blur' }
      ]
    }]
  }
}
