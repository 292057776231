import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.busManage],
    addPath: {},
    filters: [{
      label: 'studentRoad.road',
      value: 'road_id',
      option: 'road'
    }, {
      label: 'studentRoad.name',
      value: 'seat_name'
    }],
    tableItems: [{
      label: 'studentRoad.road',
      value: 'road_name'
    }, {
      label: 'studentRoad.name',
      value: 'seat_name',
      rewrite: true,
      pk: 'seat_id'
    }, {
      label: 'normal.sort',
      value: 'seat',
      rewrite: true,
      pk: 'seat_id'
    }, {
      label: 'normal.x',
      value: 'map_x',
      rewrite: true,
      pk: 'seat_id'
    }, {
      label: 'normal.y',
      value: 'map_y',
      rewrite: true,
      pk: 'seat_id'
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/road_seat/delete?id=%seat_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.schoolManage, p.busManage],
    formItems: [{
      label: 'studentRoad.road',
      value: 'road_id',
      option: 'road',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.road' }, trigger: 'blur' }
      ]
    }, {
      label: 'studentRoad.name',
      value: 'seat_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'studentRoad.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.sort',
      value: 'sort',
      default: 0,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.sort' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.x',
      value: 'map_x',
      default: 0,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.x' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.y',
      value: 'map_y',
      default: 0,
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.y' }, trigger: 'blur' }
      ]
    }]
  }
}
