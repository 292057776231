/* eslint-disable */
import user from './user'
import user_permission from './user_permission'
import school from './school'
import classes from './classes'
import class_manage from './class_manage'
import teacher from './teacher'
import teacher_pay from './teacher_pay'

import student from './student'
import student_fee from './student_fee'
import student_road from './student_road'
import student_rate from './student_rate'
import road from './road'
import road_seat from './road_seat'
import road_user from './road_user'

import customer from './customer'

import wechat_user_student from './wechat_user_student'

import score_reason from './score_reason'
import score_category from './score_category'
import stu_score_record from './stu_score_record'
import schedule_course from './schedule_course'
import school_group from './school_group'
import school_group_member from './school_group_member'

import resume from './resume'
import resume_post from './resume_post'

import store from './store'
import store_apply from './store_apply'
import store_category from './store_category'
import store_item from './store_item'
import store_record from './store_record'
import store_nothing from './store_nothing'
import sleep from './sleep'

import article_category from './article_category'
import article from './article'
import article_b from './article_b'

import visitor from './visitor'

import book from './book'
import book_category from './book_category'

import knowledge from './knowledge'
import question from './question'
import question_item from './question_item'
import question_knowledge from './question_knowledge'
import examination from './examination'
import examination_item from './examination_item'
import examination_question from './examination_question'
import chinese_text from './chinese_text'
import chinese_examination from './chinese_examination'
import chinese_examination_item from './chinese_examination_item'

import order_list from './order_list'

import report_user from './report_user'

import teacher_study from './teacher_study'

import chart_template from './chart_template'

import course from './course'
import course_change from './course_change'
import lesson from './lesson'
import lesson_pay from './lesson_pay'
import lesson_user from './lesson_user'
import textbook from './textbook'

export default {
  user,
  user_permission,
  school,
  classes,
  class_manage,
  teacher,
  teacher_pay,
  
  student,
  student_fee,
  student_road,
  student_rate,
  road,
  road_seat,
  road_user,

  customer,

  wechat_user_student,

  score_reason,
  score_category,
  stu_score_record,
  schedule_course,
  sleep,
  school_group,
  school_group_member,

  resume,
  resume_post,

  store,
  store_apply,
  store_category,
  store_item,
  store_record,
  store_nothing,

  article_category,
  article,
  article_b,

  visitor,
  
  book,
  book_category,
  
  knowledge,
  question,
  question_item,
  question_knowledge,
  examination,
  examination_item,
  examination_question,
  chinese_text,
  chinese_examination,
  chinese_examination_item,

  order_list,

  report_user,

  teacher_study,

  chart_template,

  course,
  course_change,
  lesson,
  lesson_pay,
  lesson_user,
  textbook
}
