import error from './zh_error'

const zhTr = {
  menu: {
    systemManage: {
      index: '系统管理',
      school: '学校管理',
      classes: '班级管理',
      scoreReason: '分数',
      scoreCategory: '分数类别',
      person: '个人中心修改'
    },
    inventory: {
      index: '进销存',
      store: '仓库管理',
      storeCategory: '物品分类',
      storeItem: '物品列表',
      storeNothing: '申请添加物品'
    },
    user: {
      index: '用户管理',
      user: '用户管理',
      teacher: '教师管理',
      student: '学生管理'
    },
    humanAffairs: {
      index: '人事管理',
      resume: '简历',
      resumePost: '职位编辑'
    },
    scoreManage: {
      index: '分数管理',
      stuScoreRecord: '德育分'
    },
    article: {
      index: '文章',
      articleCategory: '栏目',
      article: '文章',
      articleB: '文章栏目'
    },
    visit: {
      index: '进出入管理',
      visitor: '来访人员登记'
    },
    orderList: {
      index: '电话跟踪',
      statistic: '数据统计'
    },
    reportUser: {
      index: '网上报名'
    },
    teacherStudy: {
      index: '老师培训'
    },
    chartTemplate: {
      // 10
      index: '表单模板'
    },
    course: {
      index: '课程系统',
      template: '课程模板',
      scheduleCourse: '课程调整'
    },
    lesson: {
      index: '课程'
    },
    textbook: {
      index: '课'
    }
  },
  formRule: {
    tel: {
      required: '请输入电话号码',
      type: '请输入正确的电话号码'
    },
    email: {
      required: '请输入邮箱',
      type: '请输入正确的邮箱地址'
    },
    password: {
      required: '请输入密码',
      length: '请输入至少6位的密码'
    },
    captcha: {
      required: '请输入图形验证码',
      length: '请输入正确的图形验证码'
    },
    identityId: {
      required: '请输入身份证号',
      type: '请输入正确的身份证号'
    },
    normal: {
      required: '%{msg}不能为空',
      length: '长度在 %{min} 到 %{max} 个字符'
    }
  },
  button: {
    add: '新增',
    addMany: '新增很多',
    confirm: '确 认',
    login: '登 陆',
    logout: '登 出',
    cancel: '取消',
    up: '激活',
    down: '封禁',
    edit: '编辑',
    detail: '详细',
    delete: '删除',
    get: '生成',
    list: '列表',
    download: '下载',
    financeDetail: '财务明细下载',
    finance: '财务下载',
    agree: '同意',
    forceAgree: '强制同意',
    disagree: '不同意',
    check: '检查'
  },
  normal: {
    add: '新增',
    address: '地址',
    all: '全部',
    area: '省市区',
    answer: '答案',
    bank_card: '银行卡号',
    base: '基本信息',
    birthday: '生日',
    bothday: '生日',
    beginAt: '开始时间',
    endAt: '结束时间',
    category: '分类',
    class: '班级',
    classManage: '班主任',
    company: '公司',
    content: '内容',
    desc: '描述',
    diff: '难度',
    download: '下载',
    email: '邮箱',
    fileLimit: '文件个数超出限制',
    food: '食物',
    gender: '性别',
    grade: '年级',
    hello: '您好 ',
    headimgurl: '头像',
    id: '编号',
    identity: '身份',
    identity_id: '身份证号',
    image: '图片',
    lesson: '课程',
    methodPeople: '操作人',
    name: '姓名',
    newState: '新状态',
    number: '数目',
    oldState: '原状态',
    operate: '操作',
    password: '密码',
    price: '单价',
    post: '职位',
    process: '执行中...',
    processMessage: '请耐心等待',
    processTitle: '程序执行中',
    permission: '权限',
    parents: '父母',
    reason: '原因',
    role: '角色',
    school: '学校',
    score: '分数',
    sort: '排序',
    search: '综合查询',
    state: '状态',
    student: '学生',
    studentCode: '学号',
    teacher: '老师',
    tel: '电话号码',
    time: '时间',
    tip: '提示',
    tipDes: '请确定提交信息',
    title: '标题',
    type: '类型',
    total: '总计',
    temperature: '温度',
    tuition: '学费',
    unit: '单位',
    validata: '表单验证失败',
    wechat: '微信',
    QR: '二维码',
    limit: '限制',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    way: '方式',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    refresh: '更新',
    x: '经度',
    y: '纬度'
  },
  course: {
    courseAt: '课程开始时间',
    courseTime: '时长',
    loopClass: '循环规则',
    weekDay: '星期几',
    courseList: '第几节',
    check: '检查',
    downloadTeacher: '下载老师课表',
    downloadClass: '下载班级课表',
    create: '生成课表',
    createRange: '生成时间段',
    weekNumber: '第几周',
    weekNumberDesc: '用来确定具体课程循环方式',
    title: '课程',
    oldTitle: '原课程',
    classPre: '课程预算',
    realClass: '实际课程',
    payTip: '生成工资条',
    money: '工资',
    user: '人员设置'
  },
  teacherPay: {
    basePay: '基础工资',
    teacherPay: '全员育人',
    earlyDone: '早操',
    middleDone: '课间操',
    majorClass: '主课',
    specialClass: '特色课',
    earlyClass: '早读',
    calculateClass: '口算',
    nightClass: '畅游书海',
    middayClass: '午读',
    lessonPlan: '教案',
    homeWork: '作业',
    classroom: '班主任',
    viceClassroom: '副班主任',
    outPay: '外出津贴',
    dutyPay: '全勤',
    schoolBus: '校车',
    onDuty: '值班',
    otherPay: '其它'
  },
  lesson: {
    isGroup: '是否社团',
    lessonUser: '题库录入人'
  },
  setPerson: {
    password: '原始密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码'
  },
  error,
  user: {
    add: '新增用户'
  },
  school: {
    zhSchoolName: '学校名字',
    enSchoolName: '英文校名'
  },
  class: {
    className: '班级名称'
  },
  scoreReason: {
    enReason: '英文原因',
    zhReason: '中文原因'
  },
  category: {
    enCategory: '英文类别',
    zhCategory: '中文类别'
  },
  teacher: {
    leader: '领导'
  },
  student: {
    up: '转学',
    relationship: '家庭关系',
    isWechat: '是否登录',
    roomCode: '宿舍',
    cameAt: '入学时间',
    cameFrom: '来源',
    isLiveIn: '住校',
    desc: '备注',
    teacherName: '接待人',
    feeAt: '缴费时间'
  },
  resume: {
    contack: '联系方式',
    resume: '履历',
    major: '职务',
    beginDate: '开始时间',
    endDate: '结束时间',
    relationship: '关系',
    post: '职位',
    information: '资料',
    self: '个人总结和规划',
    isReproduction: '是否生育',
    isMarried: '是否结婚',
    politicsState: '政治面貌',
    speciality: '特长',
    computerLevel: '计算机程度',
    englishLevel: '英语水平',
    tips: '荣誉称号',
    emergencyContact: {
      name: '紧急联系人',
      tel: '紧急联系人电话'
    },
    families: '家庭成员',
    studyExperience: '学习经历',
    careerExperience: '工作经历',
    careerPlanning: '职业规划',
    classSpecial: '教学特点',
    wages: '薪资要求',
    workDate: '到岗时间',
    qualifications: '学历',
    adjust: '评价',
    rate: '评分',
    character: '性格分析',
    test1: '九重人格测试',
    test2: '性格彩色测试'
  },
  store: {
    storePosition: '存储位置',
    name: '仓库名',
    username: '操作人',
    cateName: '分类名',
    itemName: '项目名',
    content: '出入库理由',
    state: '库存变更状态',
    button: {
      add: '出入库',
      giveBack: '退还'
    },
    storeOut: '出库',
    storeIn: '入库',
    old: '折旧',
    distroy: '报损',
    serialNumber: '流水号'
  },
  articleCategory: {
    name: '分类名',
    parentName: '父分类',
    linkUrl: '链接',
    isHidden: '是否隐藏'
  },
  article: {
    title: '标题',
    icon: '图标',
    header: '描述',
    content: '内容'
  },
  visitor: {
    id: '身份证',
    comeAt: '来访时间',
    leaveAt: '到访时间'
  },
  knowledge: {
    index: '知识点',
    handleNum: '答题数量',
    handlePersent: '正确率',
    handleDiff: '难度'
  },
  question: {
    chineseText: '语文题库',
    chineseExam: '语文作业',
    isItem: '是否有小题',
    anwser: '答案',
    questionType: '题目类型',
    subQuestion: '子题目',
    isComplete: '是否归档',
    content: '解析',
    questionFrom: '题目来源',
    questionTo: '题目书目',
    questionBody: '题目主体',
    isObjective: '是否为客观题',
    item: {
      select: {
        content: '选项',
        isAnswer: '是否是正确答案',
        stype: '选项类型'
      },
      radio: {
        isRight: '是否正确'
      },
      empty: {
        content: '内容',
        answers: '答案',
        error: '请设置正确的答案数量'
      },
      doc: {
        content: '内容',
        add_content: '附加内容'
      }
    },
    diff: '平均难度',
    index: '题库',
    parse: '解析',
    isRight: '是否正确',
    editItem: '编辑选项',
    qtype: '题型'
  },
  examination: {
    index: '推题',
    upDiff: '最低难度',
    downDiff: '最高难度',
    num: '选题数量',
    diff: '难度',
    realScore: '实际分数',
    inputScore: '录分',
    class: '班级分析'
  },
  book: {
    index: '电子书',
    name: '名称',
    bookNum: '书号',
    author: '作者',
    press: '出版社',
    wordage: '字数',
    publicationDate: '出版日期'
  },
  orderList: {
    father_name: '父亲姓名',
    father_tel: '父亲电话',
    father_work: '父亲工作单位',
    mother_name: '母亲姓名',
    mother_tel: '母亲电话',
    mother_work: '母亲工作单位',
    telError: '空号错号',
    telDelay: '约访失败',
    telConfirm: '约访成功',
    comeError: '取消到访',
    comeDelay: '到访延迟',
    comeConfirm: '已到访',
    payError: '拒绝成单',
    payConfirm: '成单',
    pushPool: '强行推入池中',
    popPool: '强行指定老师',
    inputReason: '请输入原因',
    methodRecode: '操作记录',
    comeIn: '来源途径',
    delete: '强行删除',
    abandon: '剔除',
    nowLevel: '年级',
    save: '保存',
    unsave: '取消保存',
    checkList: '检查'
  },
  teacherStudy: {
    adjust: '留言',
    content: '内容'
  },
  chartTemplate: {
    // 10
    watchIdentity: '谁可以看',
    tempIdentity: '谁可以填',
    studentName: '学生姓名',
    title: '表头',
    dataType: '表类型',
    required: '是否需要',
    default: '默认值',
    option: '选项',
    table: '表格'
  },
  studentFee: {
    index: '财务信息',
    feeType: '来源',
    tuition: '学费',
    fee: '手续费',
    content: '备注',
    receiptNum: '收据号'
  },
  studentRoad: {
    index: '校车设置',
    road: '校车路线',
    seat: '校车站点',
    name: '站点名',
    menu: '整体列表',
    user: '跟车老师'
  },
  check: {
    index: '检查',
    sleep: '学生信息录入',
    courseChange: '课程调整'
  },
  schoolGroup: {
    index: '人员分组',
    teacher: '人员明细'
  },
  studentRate: {
    index: '学生评价'
  },
  customer: {
    index: '客户管理',
    student_name: '学生姓名',
    sexual: '性别',
    student_idcard: '身份证',
    school_name: '幼儿园/学校',
    class_name: '班级',
    father_name: '父亲',
    father_tel: '父亲电话',
    mother_name: '母亲',
    mother_tel: '母亲电话',
    remarks: '备注',
    come_from: '来源',
    created_at: '时间',
    docs: '跟踪记录'
  }
}

export { zhTr }
