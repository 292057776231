import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.schoolManage, p.web, p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.title',
      value: 'temp_title'
    }],
    tableItems: [{
      label: 'normal.title',
      value: 'temp_title'
    }, {
      label: 'normal.state',
      value: 'temp_type',
      option: status.chartStatus
    }, {
      label: 'chartTemplate.tempIdentity',
      value: 'temp_identity',
      option: status.writeChart
    }, {
      label: 'chartTemplate.watchIdentity',
      value: 'watch_identity',
      option: status.watchChart
    }, {
      label: 'normal.time',
      value: 'created_at'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/chart_template/edit?id=%temp_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.list',
      type: 'route',
      path: '/chart_template/chartDetail?id=%temp_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'normal.QR',
      type: 'route',
      path: '/chart_template/chartQR?id=%temp_id%',
      showIf: key => { return true },
      display: [p.schoolManage, p.web]
    }]
  },
  add: {
    permission: [p.schoolManage, p.web],
    formItems: [{
      label: 'normal.state',
      value: 'temp_type',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.state' }, trigger: 'blur' }
      ],
      option: status.chartStatus,
      default: 1
    }, {
      label: 'chartTemplate.tempIdentity',
      value: 'temp_identity',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'chartTemplate.tempIdentity' }, trigger: 'blur' }
      ],
      option: status.writeChart,
      default: ''
    }, {
      label: 'chartTemplate.watchIdentity',
      value: 'watch_identity',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'chartTemplate.watchIdentity' }, trigger: 'blur' }
      ],
      option: status.watchChart,
      default: ''
    }, {
      label: 'normal.title',
      value: 'temp_title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.desc',
      value: 'temp_desc',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.desc' }, trigger: 'blur' }
      ],
      type: 'editor'
    }],
    focus: true
  },
  edit: {
    permission: [p.schoolManage, p.web],
    formItems: [{
      label: 'normal.state',
      value: 'temp_type',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.state' }, trigger: 'blur' }
      ],
      option: status.chartStatus,
      default: 1
    }, {
      label: 'chartTemplate.tempIdentity',
      value: 'temp_identity',
      rule: [
        { required: false, message: { text: 'formRule.normal.required', msg: 'chartTemplate.tempIdentity' }, trigger: 'blur' }
      ],
      option: status.writeChart,
      default: ''
    }, {
      label: 'chartTemplate.watchIdentity',
      value: 'watch_identity',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'chartTemplate.watchIdentity' }, trigger: 'blur' }
      ],
      option: status.watchChart,
      default: ''
    }, {
      label: 'normal.title',
      value: 'temp_title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.desc',
      value: 'temp_desc',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.desc' }, trigger: 'blur' }
      ],
      type: 'editor'
    }]
  },
  chartDetail: {
    permission: [p.schoolManage, p.web, p.teacher]
  },
  chartQR: {
    permission: [p.schoolManage, p.web]
  }
}
