import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.manage],
    addPath: {},
    filters: [{
      label: 'category.enCategory',
      value: 'en_category_name',
      default: ''
    }, {
      label: 'category.zhCategory',
      value: 'zh_category_name',
      default: ''
    }],
    tableItems: [{
      label: 'category.enCategory',
      value: 'en_category_name',
      rewrite: true,
      pk: 'category_id'
    }, {
      label: 'category.zhCategory',
      value: 'zh_category_name',
      rewrite: true,
      pk: 'category_id'
    }]
  },
  add: {
    permission: [p.manage],
    formItems: [{
      label: 'category.enCategory',
      value: 'en_category_name',
      default: ''
    }, {
      label: 'category.zhCategory',
      value: 'zh_category_name',
      default: ''
    }]
  }
}
