import { p, status } from '@/plugins/permission'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)
const student = []

export default {
  index: {
    permission: [p.schoolManage, p.teacher, p.teacher, p.finance],
    addPath: {
      text: 'button.add',
      display: [p.schoolManage, p.teacher]
    },
    filters: [{
      label: 'normal.name',
      value: 'student_id',
      option: [],
      options: student,
      filterable: true,
      remoteMethod: async q => {
        if (q) {
          if (Number(q) > 0) {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?value=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          } else {
            student.splice(0, student.length)
            const res = await Vue.http.get('/api/student/getSomeOne?label=' + q)
            student.push({
              label: '全部'
            })
            for (let i = 0; i < res.data.data.length; i++) {
              student.push(res.data.data[i])
            }
          }
        }
      }
    }, {
      label: 'normal.tel',
      value: 'tel'
    }, {
      label: 'normal.name',
      value: 'real_name'
    }],
    tableItems: [{
      label: 'normal.name',
      value: 'name'
    }, {
      label: 'class.className',
      value: 'class_name'
    }, {
      label: 'normal.tel',
      value: 'tel',
      rewrite: [p.schoolManage, p.teacher],
      pk: 'link_id'
    }, {
      label: 'normal.name',
      value: 'real_name',
      rewrite: [p.schoolManage, p.teacher],
      pk: 'link_id'
    }, {
      label: 'normal.role',
      value: 'roler',
      rewrite: [p.schoolManage, p.teacher],
      pk: 'link_id'
    }, {
      label: 'student.isWechat',
      value: 'wechat_id',
      option: status.boolen,
      display: [p.schoolManage, p.teacher]
    }],
    buttonItems: [{
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/wechat_user_student/delete?id=%link_id%',
      showIf: function (key) {
        return true
      },
      display: [p.schoolManage, p.teacher]
    }]
  },
  add: {
    permission: [p.schoolManage, p.teacher],
    formItems: [{
      label: 'normal.tel',
      value: 'tel',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.tel' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.name',
      value: 'real_name',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.name' }, trigger: 'blur' }
      ]
    }, {
      label: 'normal.role',
      value: 'roler',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'normal.role' }, trigger: 'blur' }
      ],
      option: status.family
    }, {
      value: 'student_id',
      type: 'hidden'
    }]
  }
}
