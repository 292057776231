import { p, status } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.grade',
      value: 'grade',
      option: status.grade
    }, {
      label: 'question.isComplete',
      value: 'is_complete',
      option: status.boolen
    }, {
      label: 'question.questionType',
      value: 'question_type',
      option: 'question_type'
    }, {
      label: 'question.questionFrom',
      value: 'question_from',
      option: status.questionFrom,
      default: ''
    }, {
      label: 'question.questionTo',
      value: 'question_to',
      default: ''
    }],
    tableItems: [{
      label: 'question.questionFrom',
      option: status.questionFrom,
      value: 'question_from',
      width: 77
    }, {
      label: 'question.questionTo',
      value: 'question_to',
      width: 77
    }, {
      label: 'normal.title',
      value: 'title'
    }, {
      label: 'normal.content',
      value: 'item_content'
    }, {
      label: 'normal.teacher',
      value: 'name',
      width: 66
    }, {
      label: 'question.questionType',
      option: 'question_type',
      value: 'question_type',
      width: 50
    }, {
      label: 'normal.grade',
      option: status.grade,
      value: 'grade',
      width: 66
    }, {
      label: 'question.isComplete',
      value: 'is_complete',
      option: status.boolen,
      width: 38
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/question/edit?id=%question_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'question.subQuestion',
      type: 'route',
      path: '/question_item/index?question_id=%question_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'knowledge.index',
      type: 'route',
      path: '/question_knowledge/index?question_id=%question_id%',
      showIf: key => { return true }
    }, {
      buttonName: 'button.delete',
      type: 'ajax',
      path: '/api/question/delete?id=%question_id%',
      showIf: key => { return !key.is_complete }
    }]
  },
  add: {
    permission: [p.teacher],
    focus: true
  },
  edit: {
    permission: [p.teacher],
    focus: true
  }
}
