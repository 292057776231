import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.teacher],
    addPath: {},
    filters: [{
      label: 'normal.time',
      value: 'created_at',
      type: 'datetime'
    }],
    tableItems: [{
      label: 'normal.teacher',
      value: 'name'
    }, {
      label: 'normal.time',
      value: 'created_at'
    }],
    buttonItems: [{
      buttonName: 'button.get',
      type: 'route',
      path: '/chinese_papers?id=%exam_id%'
    }, {
      buttonName: 'button.detail',
      type: 'route',
      path: '/chinese_examination_item/index?exam_id=%exam_id%'
    }]
  },
  add: {
    permission: [p.teacher],
    focus: true
  },
  edit: {
    permission: [p.teacher],
    focus: true
  }
}
