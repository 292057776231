import { p } from '@/plugins/permission'

export default {
  index: {
    permission: [p.web],
    addPath: {},
    filters: [{
      label: 'article.title',
      value: 'title'
    }],
    tableItems: [{
      label: 'article.title',
      value: 'title',
      rewrite: true,
      pk: 'article_id'
    }, {
      label: 'article.header',
      value: 'parent_name'
    }],
    buttonItems: [{
      buttonName: 'button.edit',
      type: 'route',
      path: '/article/edit?id=%article_id%',
      showIf: key => { return true }
    }]
  },
  add: {
    permission: [p.web],
    formItems: [{
      label: 'article.title',
      value: 'title',
      default: '',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'article.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'article.header',
      value: 'header',
      default: ''
    }, {
      label: 'article.content',
      value: 'content',
      type: 'editor',
      default: ''
    }, {
      label: 'article.icon',
      value: 'icon',
      type: 'image',
      limit: 1,
      default: []
    }, {
      value: 'user_id',
      type: 'hidden',
      default: 10
    }]
  },
  edit: {
    permission: [p.web],
    formItems: [{
      label: 'article.title',
      value: 'title',
      rule: [
        { required: true, message: { text: 'formRule.normal.required', msg: 'article.title' }, trigger: 'blur' }
      ]
    }, {
      label: 'article.header',
      value: 'header'
    }, {
      label: 'article.content',
      value: 'content',
      type: 'editor'
    }, {
      label: 'article.icon',
      value: 'icon',
      type: 'image',
      limit: 1
    }]
  }
}
